import React, { Component } from "react";
import { Container, Form, Button, Divider, Segment } from "semantic-ui-react";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      pwd: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenForgetModal = this.handleOpenForgetModal.bind(this);
  }

  handleSubmit() {
    const { username, pwd } = this.state;
    this.setState({
      username: "",
      pwd: "",
      showForgetModal: false
    });
    var payload = {};
    payload["reqType"] = "login";
    payload["username"] = username;
    payload["pwd"] = pwd;
    let nowDate = new Date();
    payload["populateUnix"] = Math.floor(nowDate.getTime() / 1000);
    this.props.makeRequest(payload);
  }

  handleOpenForgetModal(opened) {
    this.setState({ showForgetModal: opened });
  }

  render() {
    var loginErrorText = "";
    if (this.props.wrongLogin === true) {
      loginErrorText = "Usuario y/o contraseña erróneos";
    }
    return (
      <div>
        <Segment>
          <h2 style={{ textAlign: "center" }}> Alto colono! Identifíquese </h2>{" "}
          <Form onSubmit={this.handleSubmit}>
            <Form.Input
              label="Usuario"
              placeholder="Usuario"
              value={this.state.username}
              onChange={e => this.setState({ username: e.target.value })}
            />{" "}
            <Form.Input
              type="password"
              label="Contraseña"
              placeholder="Contraseña"
              value={this.state.pwd}
              onChange={e => this.setState({ pwd: e.target.value })}
            />{" "}
            <Button
              disabled={this.state.username === "" || this.state.pwd === ""}
              positive
              type="submit"
              action=""
              style={{
                display: "block",
                margin: "0 auto",
                marginBottom: "10px"
              }}
            >
              Desembarcar en Juatan{" "}
            </Button>{" "}
            <p style={{ textAlign: "center", color: "red" }}>
              {" "}
              {loginErrorText}{" "}
            </p>{" "}
          </Form>{" "}          
        </Segment>
      </div>
    );
  }
}
