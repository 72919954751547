import React, { Component } from "react";
import { Container, Button, Table, Modal, Image } from "semantic-ui-react";

export default class SelectGameModal extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../tools/tools");
    this.state = {};
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {}

  handleSave() {}

  handleCancel() {
    this.props.hideModal();
  }

  deleteGame(gameId){
    if (window.confirm("¿Estás seguro de eliminar el juego con ID " + gameId + "?")){
      this.props.makeRequest({
        reqType: "deleteGame",
        data: gameId,
      })
    }    
  }
  

  render() {
    let gameList = [];
    gameList.push(
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Fecha de creación</Table.HeaderCell>
        <Table.HeaderCell>Descripción</Table.HeaderCell>
        <Table.HeaderCell>Jugadores</Table.HeaderCell>
        <Table.HeaderCell>Acción</Table.HeaderCell>
      </Table.Row>
    );

    let playerNames;
    for (let [key, value] of Object.entries(this.props.data.availableGames)) {
      playerNames = "";
      for (let i = 1; i < 7; i++) {
        if (value["p" + i] != -1) {
          if (i != 1) {
            playerNames += ", ";
          }
          playerNames += this.props.users[value["p" + i]].username;
        }
      }
      gameList.push(
        <Table.Row>
          <Table.Cell>{value.gameId}</Table.Cell>
          <Table.Cell>
            {this.tools.getDateAndTime(value.createdTime, true)}
          </Table.Cell>
          <Table.Cell>{value.description}</Table.Cell>
          <Table.Cell>{playerNames}</Table.Cell>
          <Table.Cell>
            <Button
              disabled={this.props.data.game.gameId == value.gameId}
              size="mini"
              color="green"
              content={
                this.props.data.game.gameId == value.gameId ? "Actual" : "Ir"
              }
              onClick={() => {
                this.props.makeRequest({
                  reqType: "changeActiveGame",
                  data: value.gameId,
                });
                this.props.toggleSelectGameModal(false);
              }}
            />
            {this.props.isAdmin && (
              <Button
                size="mini"
                color="black"
                content={"Eliminar"}
                onClick={() => {
                  this.deleteGame(value.gameId)
                }}
              />
            )}
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>Gestor de partidas</Modal.Header>
          <Modal.Content>
            <h4>Seleccione partida</h4>
            <Table celled>{gameList}</Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Volver"
              onClick={() => this.props.toggleSelectGameModal(false)}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
