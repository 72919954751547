import React, { Component } from "react";
import { Container, Button, Checkbox, Modal } from "semantic-ui-react";

export default class MakeOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,
      usersOffered: [],
      resourcesOffered: [],
      resourcesRequested: []
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);

    this.resourceName = ["gr", "wo", "ti", "cl", "st"];
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    newState.usersOffered = [0,0,0,0,0,0,0,0];
    newState.resourcesOffered = [0, 0, 0, 0, 0];
    newState.resourcesRequested = [0, 0, 0, 0, 0];
    this.setState(newState);
  }

  handleChange(type, pos, delta = 0) {
    let newState = this.state;
    if (type == "user") {
      if (pos == 0) {
        let oneUnchecked = false;
        for (let i = 1; i < 7; i++) {
          if (
            newState.usersOffered[i] == 0 &&
            this.props.userId != this.props.data.game["p" + i] &&
            this.props.data.game["p" + i] != -1
          ) {
            oneUnchecked = true;
          }
        }
        for (let i = 1; i < 7; i++) {
          if (this.props.userId != this.props.data.game["p" + i]
              && -1 != this.props.data.game["p" + i]) {
            if (oneUnchecked == true) {
              newState.usersOffered[i] = 1;
            } else {
              newState.usersOffered[i] = 0;
            }
          }
        }
        newState.usersOffered[7] = 0;
      } else if (pos == 7){
        newState.usersOffered[7] = newState.usersOffered[7]==1?0:1;
        for (let i = 0; i<7; i++){
          newState.usersOffered[i] = 0;
        }
      } else {        
        newState.usersOffered[pos] = newState.usersOffered[pos]==1?0:1;
        newState.usersOffered[7] = 0;
      }
      let allChecked = true;
      let oneChecked = false;
      for (let i = 1; i < 7; i++) {
        if (this.props.userId != this.props.data.game["p" + i] && 
              -1 != this.props.data.game["p" + i]) {
          if (newState.usersOffered[i] == 0) {
            allChecked = false;
          } else {
            oneChecked = true;
          }
        }
      }
      newState.usersOffered[0] = allChecked?1:0;
      this.setState({ usersOffered: newState.usersOffered });
    } else if (type == "offered") {
      newState.resourcesOffered[pos] += delta;
      if (newState.resourcesOffered[pos] < 0) {
        newState.resourcesOffered[pos] = 0;
      }
      if (
        this.props.data.cards[this.props.userId][this.resourceName[pos]] <
        newState.resourcesOffered[pos]
      ) {
        newState.resourcesOffered[pos] = this.props.data.cards[
          this.props.userId
        ][this.resourceName[pos]];
      }
      this.setState({ resourcesOffered: newState.resourcesOffered });
    } else if (type == "requested") {
      newState.resourcesRequested[pos] += delta;
      if (newState.resourcesRequested[pos] < 0) {
        newState.resourcesRequested[pos] = 0;
      }
      this.setState({ resourcesRequested: newState.resourcesRequested });
    }
    let newValidData = this.validate(newState);
    this.setState({ validData: newValidData });
  }

  validate(stateChecked) {
    let isValid1 = false;
    for (let i = 1; i < 7; i++) {
      if (this.props.userId != this.props.data.game["p" + i]
            && this.props.data.game["p" + i] != -1) {
        if (stateChecked.usersOffered[i] == 1) {
          isValid1 = true;
        }
      }
    }
    if (isValid1 == true && stateChecked.usersOffered[7] == 1){
      isValid1 = false;
    }else if (isValid1 == false && stateChecked.usersOffered[7] == 1){
      isValid1 = true;
    }

    let isValid2 = false;
    let isValid3 = false;
    for (let i = 0; i < 5; i++) {
      if (stateChecked.resourcesOffered[i] > 0) {
        isValid2 = true;
      }
      if (stateChecked.resourcesRequested[i] > 0) {
        isValid3 = true;
      }
    }
    let isValid = isValid1 && isValid2 && isValid3;
    return isValid;
  }

  handleSave() {
    //make request
    if (this.validate(this.state)) {      
      let args = {};
      args["reqType"] = "makeOffer";
      args["data"]={};
      args["data"]['usersOffered'] = this.state.usersOffered;
      args["data"]['resourcesOffered'] = this.state.resourcesOffered;
      args["data"]['resourcesRequested'] = this.state.resourcesRequested;            
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  render() {
    //debugger;

    const usersOfferedItems = [];
    usersOfferedItems[0] = (
      <div>
        <Checkbox
          checked={this.state.usersOffered[0]}
          id={0}
          label="Todos los jugadores"
          onChange={() => this.handleChange("user", 0)}
        />
      </div>
    );
    for (let i = 1; i < 7; i++) {
      if (this.props.userId != this.props.data.game["p" + i] && this.props.data.game["p" + i] != -1) {
        usersOfferedItems[i] = (
          <div>
            <Checkbox
              checked={this.state.usersOffered[i]}
              id={i}
              label={this.props.users[this.props.data.game["p" + i]].username}
              onChange={() => this.handleChange("user", i)}
            />
          </div>
        );
      }
    }
    usersOfferedItems[7] = (
      <div style={{marginTop: '10px'}}>
        <Checkbox
          checked={this.state.usersOffered[7]}
          id={7}
          label="Banca"
          onChange={() => this.handleChange("user", 7)}
        />
      </div>
    );

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Realizar oferta"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div>
                <b>¿A quien le haces la oferta?</b>
              </div>
              {usersOfferedItems}
            </Modal.Description>
            <hr></hr>
            <div className="resource-grid">
              <div className="resource-column-1">
                <div>
                  <b>Ofrezco</b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Trigo
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[0] +
                      " / " +
                      this.props.data.cards[this.props.userId]["gr"]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 0, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 0, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Lana
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[1] +
                      " / " +
                      this.props.data.cards[this.props.userId]["wo"]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 1, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 1, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Madera
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[2] +
                      " / " +
                      this.props.data.cards[this.props.userId]["ti"]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 2, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 2, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Arcilla
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[3] +
                      " / " +
                      this.props.data.cards[this.props.userId]["cl"]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 3, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 3, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Piedra
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[4] +
                      " / " +
                      this.props.data.cards[this.props.userId]["st"]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 4, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("offered", 4, 1)}
                    />
                  </div>
                </div>
              </div>
              <div className="resource-column-2">
                <div>
                  <b>Recibo</b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Trigo
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[0]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 0, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 0, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Lana
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[1]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 1, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 1, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Madera
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[2]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 2, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 2, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Arcilla
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[3]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 3, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 3, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Piedra
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[4]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 4, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange("requested", 4, 1)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Cancelar"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Realizar oferta"
              onClick={() => this.handleSave()}
              disabled={!this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
