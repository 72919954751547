import React, { Component } from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import CreateGameModal from "./CreateGameModal";
import GiveResourcesModal from "./GiveResourcesModal/GiveResourcesModal";
import PlayerCard from "./PlayerCard";
import TurnPanel from "./TurnPanel/TurnPanel";
import GameStatus from "./GameStatus.js";
import MakeOfferModal from "./MakeOfferModal";
import SeeOfferModal from "./SeeOfferModal";
import EvaluateOfferModal from "./EvaluateOfferModal";
import BuyModal from "./BuyModal";
import UseCardModal from "./UseCardModal";
import LogPanel from "./LogPanel";
import ThiefModal from "./ThiefModal";
import MoveThiefPanel from "./MoveThiefPanel";
import MapPanel from "./MapPanel";
import EditMapPanel from "./EditMapPanel";
import VictoryModal from "./VictoryModal";
import SelectGameModal from "./SelectGameModal";
//import EditMapModal from "./EditMapModal";

import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/icomoon/home";
export const IconHome = () => <Icon icon={home} />;

export default class ViewGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateGameModal: false,
      showGiveResourcesModal: false,
      showMakeOfferModal: false,
      showSeeOfferModal: false,
      showEvaluateOfferModal: false,
      showBuyModal: false,
      showUseCardModal: false,
      showUseThiefModal: false,
      showMoveThiefPanel: false,
      showVictoryModal: true,
      paused: -1,
      showNodeIds: false,
      showEditMapPanel: false,
      hideWinnerModal: false,
      editMapPanelData: {
        node1: 0,
        node2: 0,
        type: "",
        user: 0,
      },
      thiefTerrain: "",
    };

    this.hideModal = this.hideModal.bind(this);
    this.setEnable = this.setEnable.bind(this);
    this.toggleNodeIds = this.toggleNodeIds.bind(this);
    this.setShowVictoryModal = this.setShowVictoryModal.bind(this);
    this.toggleEditMap = this.toggleEditMap.bind(this);
    this.updateEditMapPanelData = this.updateEditMapPanelData.bind(this);
    this.updateThiefTerrain = this.updateThiefTerrain.bind(this);
  }

  updateEditMapPanelData(data){
    this.setState({editMapPanelData: data});
  }

  updateThiefTerrain(terrain){
    this.setState({thiefTerrain: terrain});
  }


  toggleNodeIds() {
    this.setState({
      showNodeIds: !this.state.showNodeIds,
    });
  }
  setShowVictoryModal(value) {
    this.setState({
      showVictoryModal: value,
    });
  }
  toggleEditMap() {
    let newShowEditMapPanel = !this.state.showEditMapPanel;
    this.hideModal();
    this.setState({
      showEditMapPanel: newShowEditMapPanel,
    });
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    if (propsCopy.data.game.paused != this.state.paused) {
      this.setState({ paused: propsCopy.data.game.paused });
    }
  }

  hideModal() {
    this.setState({
      showCreateGameModal: false,
      showGiveResourcesModal: false,
      showMakeOfferModal: false,
      showSeeOfferModal: false,
      showEvaluateOfferModal: false,
      showBuyModal: false,
      showUseCardModal: false,
      showUseThiefModal: false,
      showMoveThiefPanel: false,
      showEditMapPanel: false,
      showNodeIds: false,
      showVictoryModal: false,
    });
  }

  setEnable(newState) {
    this.hideModal();
    this.setState(newState);
  }

  render() {
    //Non Admin render
    if (this.props.userId == 0) {
      return "";
    }

    if (
      (this.props.userId !== this.props.data.game.p1 &&
        this.props.userId !== this.props.data.game.p2 &&
        this.props.userId !== this.props.data.game.p3 &&
        this.props.userId !== this.props.data.game.p4 &&
        this.props.userId !== this.props.data.game.p5 &&
        this.props.userId !== this.props.data.game.p6) ||
      this.props.data.game == false
    ) {
      return (
        <Segment>
          <h4 style={{ textAlign: "center" }}>
            No hay una partida iniciada para ti en este momento
          </h4>

          {this.props.isAdmin && (
            <div style={{textAlign: "center"}}>
              <Button                                
                color="black"
                icon="redo"
                content="Crear partida"
                onClick={() => {
                  this.setState({ showCreateGameModal: !this.state.showCreateGameModal });
                }}
              />
            </div>
          )}
          {this.state.showCreateGameModal && (
            <CreateGameModal
              users={this.props.data.users}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
            />
          )}
        </Segment>
      );
    } else {
      return (
        <div>
          <div className="leftDisplay">
            <Segment style={{ marginLeft: "10px", marginRight: "10px" }}>
              <TurnPanel data={this.props.data} />
            </Segment>
          </div>

          <div className="rightDisplay">
            <div>
              <Segment
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                  padding: "5px",
                }}
              >
                <GameStatus
                  data={this.props.data}
                  hideModal={this.hideModal}
                  isAdmin={this.props.isAdmin}
                  usingAdmin={this.props.usingAdmin}
                  makeRequest={this.props.makeRequest}
                  setEnable={this.setEnable}
                  card={this.props.data.cards[this.props.userId]}
                  userId={this.props.userId}
                  showEditMapPanel={this.state.showEditMapPanel}
                  showMoveThiefPanel={this.state.showMoveThiefPanel}
                  toggleEditMap={this.toggleEditMap}
                  setShowVictoryModal={this.setShowVictoryModal}
                />
              </Segment>
            </div>

            <div>
              <div className="mapDisplay">
                <Segment
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                    padding: "5px",
                  }}
                >
                  <MapPanel
                    terrains={this.props.data.terrains}
                    roads={this.props.data.roads}
                    towns={this.props.data.towns}
                    cardSummary={this.props.data.cardSummary}
                    showNodeIds={this.state.showNodeIds}
                    game={this.props.data.game}
                    mapVersion={this.props.data.game.nPlayers > 4 ? 2 : 1}
                    hexaHeight={this.props.data.game.nPlayers > 4 ? 33 : 40}
                    showEditMapPanel={this.state.showEditMapPanel}
                    updateEditMapPanelData={this.updateEditMapPanelData}
                    editMapPanelData={this.state.editMapPanelData}
                    showEditMapPanel={this.state.showEditMapPanel}
                    showMoveThiefPanel={this.state.showMoveThiefPanel}
                    updateThiefTerrain={this.updateThiefTerrain}
                    thiefTerrain={this.state.thiefTerrain}
                  />
                </Segment>
              </div>

              <div className="logDisplay">
                {!this.state.showEditMapPanel &&
                  !this.state.showMoveThiefPanel && (
                    <Segment style={{ marginRight: "10px" }}>
                      <LogPanel
                        data={this.props.data}
                        userId={this.props.userId}
                      />
                    </Segment>
                  )}
                {this.state.showEditMapPanel && (
                  <Segment style={{ marginRight: "10px" }}>
                    <EditMapPanel
                      data={this.props.data}
                      makeRequest={this.props.makeRequest}
                      hideModal={this.hideModal}
                      userId={this.props.userId}
                      toggleNodeIds={this.toggleNodeIds}
                      showNodeIds={this.state.showNodeIds}
                      isAdmin={this.props.isAdmin}
                      usingAdmin={this.props.usingAdmin}
                      updateEditMapPanelData={this.updateEditMapPanelData}
                      editMapPanelData={this.state.editMapPanelData}
                    />
                  </Segment>
                )}
                {this.state.showMoveThiefPanel && (
                  <Segment style={{ marginRight: "10px" }}>
                    <MoveThiefPanel
                      data={this.props.data}
                      makeRequest={this.props.makeRequest}
                      hideModal={this.hideModal}
                      userId={this.props.userId}
                      toggleNodeIds={this.toggleNodeIds}
                      showNodeIds={this.state.showNodeIds}
                      thiefTerrain={this.state.thiefTerrain}
                      updateThiefTerrain={this.updateThiefTerrain}
                    />
                  </Segment>
                )}
              </div>
            </div>
          </div>
          {this.state.showCreateGameModal && (
            <CreateGameModal
              users={this.props.data.users}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
            />
          )}
          {this.state.showGiveResourcesModal && (
            <GiveResourcesModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
            />
          )}
          {this.state.showMakeOfferModal && (
            <MakeOfferModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showSeeOfferModal && (
            <SeeOfferModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showEvaluateOfferModal && (
            <EvaluateOfferModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showBuyModal && (
            <BuyModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showUseCardModal && (
            <UseCardModal
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showUseThiefModal && (
            <ThiefModal
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
          {this.state.showVictoryModal && this.props.data.game.winner != 0 && (
            <VictoryModal
              users={this.props.data.users}
              data={this.props.data}
              hideModal={this.hideModal}
              userId={this.props.userId}
              setShowVictoryModal={this.setShowVictoryModal}
            />
          )}
          {this.props.showSelectGameModal && (
            <SelectGameModal
              users={this.props.data.users}
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
              toggleSelectGameModal={this.props.toggleSelectGameModal}
              isAdmin={this.props.isAdmin}
            />
          )}
        </div>
      );
    }
  }
}

/*
{this.state.showEditMapModal && (
  <EditMapModal
    data={this.props.data}
    makeRequest={this.props.makeRequest}
    hideModal={this.hideModal}
    userId={this.props.userId}
  />
)}

{this.state.showMoveThiefModal && (
            <MoveThiefPanel
              data={this.props.data}
              makeRequest={this.props.makeRequest}
              hideModal={this.hideModal}
              userId={this.props.userId}
            />
          )}
*/
