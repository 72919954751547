import React, { Component } from "react";
import { Container, Button, Checkbox, Modal, Image } from "semantic-ui-react";

export default class VictoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };    
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    
  }  

  handleSave() {    
  }

  handleCancel() {
    this.props.hideModal();
  }
  

  render() {        

    let totalPoints = 0;
    totalPoints += 2 * this.props.data.cards[this.props.data.game.winner].tow;
    totalPoints += 2 * this.props.data.cards[this.props.data.game.winner].ci;
    totalPoints += this.props.data.cards[this.props.data.game.winner].vi;
    if (this.props.data.game['p' + this.props.data.game.routeOwner] == this.props.data.game.winner){
      totalPoints += 2;
    }
    if (this.props.data.game['p' + this.props.data.game.armyOwner] == this.props.data.game.winner){
      totalPoints += 2;
    }

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>Victoria</Modal.Header>
          <Modal.Content>
            <h1 style={{textAlign: "center"}}>¡{this.props.data.users[this.props.data.game.winner].username} ha ganado!</h1>
            <div style={{textAlign: "center"}}>
              <div>
                <div className="vic-cell">                  
                </div>
                <div className="vic-cell">
                  Número
                </div>
                <div className="vic-cell">
                  Puntos
                </div>
              </div>
              <div>
                <div className="vic-cell">
                  Pueblos
                </div>
                <div className="vic-cell">
                  {this.props.data.cards[this.props.data.game.winner].tow}
                </div>
                <div className="vic-cell">
                  {2 * this.props.data.cards[this.props.data.game.winner].tow}
                </div>
              </div>
              {this.props.data.cards[this.props.data.game.winner].ci > 0 && (
                <div>
                  <div className="vic-cell">
                    Ciudades
                  </div>
                  <div className="vic-cell">
                    {this.props.data.cards[this.props.data.game.winner].ci}
                  </div>
                  <div className="vic-cell">
                    {2 * this.props.data.cards[this.props.data.game.winner].ci}
                  </div>
                </div>
              )}
              {this.props.data.game['p' + this.props.data.game.routeOwner] == this.props.data.game.winner && (
                <div>
                  <div className="vic-cell">
                    Ruta comercial
                  </div>
                  <div className="vic-cell">                  
                    1
                  </div>
                  <div className="vic-cell">
                    2
                  </div>
                </div>
              )}
              {this.props.data.game['p' + this.props.data.game.armyOwner] == this.props.data.game.winner && (
                <div>
                  <div className="vic-cell">
                    Gran armada
                  </div>
                  <div className="vic-cell">                  
                    1
                  </div>
                  <div className="vic-cell">
                    2
                  </div>
                </div>
              )}
              {this.props.data.cards[this.props.data.game.winner].vi > 0 && (
                <div>
                  <div className="vic-cell">
                    Cartas puntos victoria
                  </div>
                  <div className="vic-cell">
                    {this.props.data.cards[this.props.data.game.winner].vi}
                  </div>
                  <div className="vic-cell">
                    {this.props.data.cards[this.props.data.game.winner].vi}
                  </div>
                </div>
              )}
              <div>
                <div className="vic-cell">
                  Total
                </div>
                <div className="vic-cell">                  
                </div>
                <div className="vic-cell">
                  {totalPoints}
                </div>
              </div>
            </div>

          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Volver"
              onClick={() => this.props.setShowVictoryModal(false)}
            />            
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
