import React, { Component } from "react";
import { Container, Button, Grid, Modal, Checkbox } from "semantic-ui-react";
import NewResourcesCard from "./NewResourcesCard";

export default class GiveResourcesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,
      resourcesGiven: {}
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);
    this.setArmy = this.setArmy.bind(this);
    this.setRoute = this.setRoute.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newResourcesGiven = this.state.resourcesGiven;
    for (let i = 1; i < 7; i++) {
      if (receivedProps.data.game["p" + i] != -1) {
        newResourcesGiven[receivedProps.data.game["p" + i]] = {};
        newResourcesGiven[receivedProps.data.game["p" + i]].gr = 0;
        newResourcesGiven[receivedProps.data.game["p" + i]].wo = 0;
        newResourcesGiven[receivedProps.data.game["p" + i]].ti = 0;
        newResourcesGiven[receivedProps.data.game["p" + i]].cl = 0;
        newResourcesGiven[receivedProps.data.game["p" + i]].st = 0;
      }
    }
    this.setState({
      resourcesGiven: newResourcesGiven,
      armyOwner: receivedProps.data.game.armyOwner,
      routeOwner: receivedProps.data.game.routeOwner,
    });
  }

  handleChange(userId, resourceType, delta) {
    let newResourcesGiven = this.state.resourcesGiven;
    newResourcesGiven[userId][resourceType] += delta;
    this.setState({
      resourcesGiven: newResourcesGiven
    });
  }

  validate() {
    return true;
  }

  handleSave() {
    //make request
    if (this.validate()) {
      let args = {};
      args["reqType"] = "giveResources";
      args["data"] = {};
      args["data"]['res'] = this.state.resourcesGiven;
      args["data"]["route"] = this.state.routeOwner;
      args["data"]["army"] = this.state.armyOwner;
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  handleSelectors(e, data) {
    //this.setState({ resourceChecked: data.value });
  }

  setArmy(i) {
    this.setState({ armyOwner: i });
  }

  setRoute(i) {
    this.setState({ routeOwner: i });
  }

  render() {
    let gridWidth = 5;

    let routeItems = [];
    routeItems[0] = (
      <Checkbox
        checked={this.state.routeOwner == 0}
        id={'a'+0}
        label={"Ninguno"}
        onChange={() => this.setRoute(0)}
      />
    );
    for (let i = 1; i < 7; i++) {
      if (this.props.data.game["p" + i] != -1) {
        routeItems[i] = (
          <div style={{ display: "inline", margin: "20px" }}>
            <Checkbox
              checked={this.state.routeOwner == i}
              id={'a' + i}
              label={
                this.props.data.users[this.props.data.game["p" + i]]["username"]
              }
              onChange={() => this.setRoute(i)}
            />
          </div>
        );
      }
    }

    let armyItems = [];
    armyItems[0] = (
      <Checkbox
        checked={this.state.armyOwner == 0}
        id={0}
        label={"Ninguno"}
        onChange={() => this.setArmy(0)}
      />
    );    
    for (let i = 1; i < 7; i++) {
      if (this.props.data.game["p" + i] != -1) {
        armyItems[i] = (
          <div style={{ display: "inline", margin: "20px" }}>
            <Checkbox
              checked={this.state.armyOwner == i}
              id={i}
              label={
                this.props.data.users[this.props.data.game["p" + i]]["username"]
              }
              onChange={() => this.setArmy(i)}
            />
          </div>
        );
      }
    }    

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Repartir recursos"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {Object.keys(this.state.resourcesGiven).length > 0 && (
                <div>
                  <Grid>
                    <Grid.Column width={gridWidth}>
                      <Grid.Row>
                        <NewResourcesCard
                          userId={this.props.data.game.p1}
                          color={
                            this.props.data.cardSummary[this.props.data.game.p1]
                              .color
                          }
                          username={
                            this.props.data.users[this.props.data.game.p1]
                              .username
                          }
                          resourcesGiven={
                            this.state.resourcesGiven[this.props.data.game.p1]
                          }
                          handleChange={this.handleChange}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <NewResourcesCard
                          userId={this.props.data.game.p2}
                          color={
                            this.props.data.cardSummary[this.props.data.game.p2]
                              .color
                          }
                          username={
                            this.props.data.users[this.props.data.game.p2]
                              .username
                          }
                          resourcesGiven={
                            this.state.resourcesGiven[this.props.data.game.p2]
                          }
                          handleChange={this.handleChange}
                        />
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={gridWidth}>
                      <Grid.Row>
                        <NewResourcesCard
                          userId={this.props.data.game.p3}
                          color={
                            this.props.data.cardSummary[this.props.data.game.p3]
                              .color
                          }
                          username={
                            this.props.data.users[this.props.data.game.p3]
                              .username
                          }
                          resourcesGiven={
                            this.state.resourcesGiven[this.props.data.game.p3]
                          }
                          handleChange={this.handleChange}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        {this.props.data.game.p4 != -1 && (
                          <NewResourcesCard
                            userId={this.props.data.game.p4}
                            color={
                              this.props.data.cardSummary[
                                this.props.data.game.p4
                              ].color
                            }
                            username={
                              this.props.data.users[this.props.data.game.p4]
                                .username
                            }
                            resourcesGiven={
                              this.state.resourcesGiven[this.props.data.game.p4]
                            }
                            handleChange={this.handleChange}
                          />
                        )}
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={gridWidth}>
                      <Grid.Row>
                        {this.props.data.game.p5 != -1 && (
                          <NewResourcesCard
                            userId={this.props.data.game.p5}
                            color={
                              this.props.data.cardSummary[
                                this.props.data.game.p5
                              ].color
                            }
                            username={
                              this.props.data.users[this.props.data.game.p5]
                                .username
                            }
                            resourcesGiven={
                              this.state.resourcesGiven[this.props.data.game.p5]
                            }
                            handleChange={this.handleChange}
                          />
                        )}
                      </Grid.Row>
                      <Grid.Row>
                        {this.props.data.game.p6 != -1 && (
                          <NewResourcesCard
                            userId={this.props.data.game.p6}
                            color={
                              this.props.data.cardSummary[
                                this.props.data.game.p6
                              ].color
                            }
                            username={
                              this.props.data.users[this.props.data.game.p6]
                                .username
                            }
                            resourcesGiven={
                              this.state.resourcesGiven[this.props.data.game.p6]
                            }
                            handleChange={this.handleChange}
                          />
                        )}
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                  <div>
                    <p>Armada:</p>
                    <div>{armyItems}</div>
                  </div>
                  <div>
                    <p>Ruta:</p>
                    <div>{routeItems}</div>
                  </div>
                </div>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Cancelar"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Guardar cambios"
              onClick={() => this.handleSave()}
              disabled={this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
