function getTimeTextFromUnix(unix, full = false) {
    let dateAux = new Date(unix * 1000);
    let hours = dateAux.getHours();
    if (hours < 10) { hours = "0" + hours; }
    let minutes = dateAux.getMinutes();
    if (minutes < 10) { minutes = "0" + minutes; }
    if (full === true) {
        let seconds = dateAux.getSeconds();
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ":" + minutes + ":" + seconds;
    } else {
        return hours + ":" + minutes;
    }
}
function getHHMMSS(time) {    
    let hours = Math.floor(time / 3600);
    time -= hours * 3600;
    let minutes = Math.floor(time / 60);
    let seconds = time - 60 * minutes;
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ":" + minutes + ":" + seconds;    
}

function getDateAndTime(unixtime){
    var a = new Date(unixtime * 1000);
    var months = ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    if (min<10){min = "0"+min;}
    var sec = a.getSeconds();
    if (sec<10){sec = "0"+sec;}
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }
  
exports.getTimeTextFromUnix = getTimeTextFromUnix;
exports.getHHMMSS = getHHMMSS;
exports.getDateAndTime = getDateAndTime;