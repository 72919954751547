import React, { Component, Fragment, Prompt } from "react";
import { Menu, Dropdown, Icon, Label, Button } from "semantic-ui-react";
import "./styles.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../tools/tools");
    this.state = {
      lastRefreshUnix: 0,
    };
  }

  /*  componentDidMount(){
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps !== this.props){
      this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps){
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState=this.state;
    newState.lastRefreshUnix= propsCopy.lastRefreshUnix;    
    this.setState(newState);
  }
*/
  render() {
    let refreshText =
      "Actualizado a las " +
      this.tools.getTimeTextFromUnix(this.props.lastRefreshUnix, true);

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            float: "left",
            height: "100%",
            width: "15%",
          }}
        >
          <Button
            size="mini"
            style={{ margin: "8px" }}
            disabled={!this.props.logged}
            color="grey"
            icon="list"
            content={"Juego: " + this.props.gameId}
            onClick={() => this.props.toggleSelectGameModal()}
          />
        </div>{" "}
        <div
          style={{
            float: "left",
            height: "100%",
            width: "35%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              padding: "10px",
              color: "yellow",
              fontSize: "18px",
              fontWeight: "900",
              fontStyle: "italic",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            }}
          >
            JUATAN{" "}
          </p>{" "}
        </div>{" "}
        <div
          style={{
            float: "left",
            height: "100%",
            width: "20%",
          }}
        ></div>
        <div
          style={{
            float: "left",
            height: "100%",
            width: "15%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              padding: "10px",
              color: "yellow",
              fontSize: "12px",
              fontWeight: "900",
              fontStyle: "italic",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            }}
          >
            {" "}
            {refreshText}{" "}
          </p>
        </div>
        <div
          style={{
            float: "left",
            height: "100%",
            width: "15%",
          }}
        >
          <Button
            size="mini"
            style={{ margin: "8px" }}
            disabled={!this.props.logged}
            color="grey"
            icon="log out"
            content={this.props.username}
            onClick={() => this.props.closeSession()}
          />
          
          {this.props.isAdmin &&
            (<Button
              size="mini"
              style={{ margin: "8px" }}
              color={this.props.usingAdmin ?  "green" : "grey"}
              icon={this.props.usingAdmin ?  "eye" : "eye slash"}
              content="Admin"
              onClick={() => this.props.toggleUsingAdmin()}
            />)
          }

        </div>
      </div>
    );
  }
}
