import React, { Component } from "react";
import { Container, Icon, Popup, Grid } from "semantic-ui-react";
import { nodeCoord, roadNodes, terrainNodes } from "./mapParameters.js";

export default class MapPanel extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../tools/tools.js");

    this.updateCanvas = this.updateCanvas.bind(this);
    this.xOffset = this.xOffset.bind(this);
    this.yOffset = this.yOffset.bind(this);
    this.pOffset = this.pOffset.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.juatanLine = this.juatanLine.bind(this);
    this.drawCircle = this.drawCircle.bind(this);
    this.juatanTown = this.juatanTown.bind(this);
    this.roundRect = this.roundRect.bind(this);

    this.canvasWidth = 600;
    this.canvasHeight = 420;
    this.xOffsetParameter = 85;
    this.yOffsetParameter = this.canvasHeight - 10;

    this.gold = '#FFD700';
    this.lime = '#00FF00';
    this.darkGreen = '#006400';
    this.black = '#000000';
    this.grey = '#808080';
    this.brown = '#A52A2A';
    this.khaki = '#F0E68C';
    this.paleGoldenRod = '#EEE8AA';
    this.lightBlue = '#ADD8E6';
    this.red = '#FF0000';
    this.white = '#FFFFFF';

    this.counter = 0;
    
  }

  componentDidMount() {
    this.updateCanvas();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.terrains !== this.props.terrains) {
      this.updateCanvas();
    }
  }

  xOffset(x) {
    return x + this.xOffsetParameter;
  }
  yOffset(y) {
    return this.yOffsetParameter - y;
  }
  pOffset(p) {
    return { x: p.x + this.xOffsetParameter, y: this.yOffsetParameter - p.y };
  }

  drawLine(p1, p2, color, width) {
    let ctx = this.refs["juatanCanvas"].getContext("2d");

    p1 = this.pOffset(p1);
    p2 = this.pOffset(p2);

    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineWidth = width;
    ctx.beginPath();
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(p2.x, p2.y);
    ctx.closePath();
    ctx.stroke();
  }

  updateCanvas() {
    let ctx = this.refs["juatanCanvas"].getContext("2d");
    //Borrar
    ctx.clearRect(
      0,
      0,
      this.refs["juatanCanvas"].width,
      this.refs["juatanCanvas"].height
    );

    //Fondo azul
    ctx.beginPath();
    ctx.rect(0, 0, this.canvasWidth, this.canvasHeight);
    ctx.fillStyle = this.lightBlue;
    ctx.fill();

    ctx.lineWidth = 1;

    //Draw borders
    /*for (let key in roadNodes) {
      this.juatanLine(
        nodeCoord[roadNodes[key][0]].x,
        nodeCoord[roadNodes[key][0]].y,
        nodeCoord[roadNodes[key][0]].z,
        nodeCoord[roadNodes[key][1]].x,
        nodeCoord[roadNodes[key][1]].y,
        nodeCoord[roadNodes[key][1]].z,
        "#000000",
        1
      );
    }*/

    //Draw blank hexagons
    let nHexa = 19;
    if (this.props.mapVersion == 2) {
      nHexa = 30;
    }
    for (let i = 1; i <= nHexa; i++) {
      this.juatanHexagon(
        terrainNodes[this.props.mapVersion][i]["x"],
        terrainNodes[this.props.mapVersion][i]["y"],
        terrainNodes[this.props.mapVersion][i]["z"],
        this.getColor(this.props.terrains[i].type),
        this.props.terrains[i].diceNumber,
        true,
        false
      );
    }

    //Draw hexagons
    for (let i = 1; i <= nHexa; i++) {
      this.juatanHexagon(
        terrainNodes[this.props.mapVersion][i]["x"],
        terrainNodes[this.props.mapVersion][i]["y"],
        terrainNodes[this.props.mapVersion][i]["z"],
        this.getColor(this.props.terrains[i].type),
        this.props.terrains[i].diceNumber,
        false,
        i == this.props.game.thiefLocation
      );
    }

    
    //Draw ports
    if (this.props.mapVersion == 1) {
      this.drawCircle({ x: 173, y: 22 }, 10, "2:1", this.brown, this.white, 12);
      this.drawCircle({ x: 309, y: 22 }, 10, "3:1", this.black, this.white, 12);
      this.drawCircle({ x: 375, y: 138 }, 10, "3:1", this.black, this.white, 12);
      this.drawCircle({ x: 377, y: 277 }, 10, "2:1", this.lime, this.white, 12);
      this.drawCircle({ x: 308, y: 394 }, 10, "3:1", this.black, this.white, 12);
      this.drawCircle({ x: 172, y: 392 }, 10, "2:1", this.grey, this.white, 12);
      this.drawCircle({ x: 52, y: 325 }, 10, "2:1", this.gold, this.white, 12);
      this.drawCircle({ x: -15, y: 208 }, 10, "3:1", this.black, this.white, 12);
      this.drawCircle({ x: 53, y: 90 }, 10, "2:1", this.darkGreen, this.white, 12);
    } else if (this.props.mapVersion == 2) {      
      this.drawCircle({ x: 256, y: 17 }, 9, "2:1", this.grey, this.white, 10);
      this.drawCircle({ x: 344, y: 57 }, 9, "3:1", this.black, this.white, 10);
      this.drawCircle({ x: 385, y: 132 }, 9, "2:1", this.gold, this.white, 10);
      this.drawCircle({ x: 444, y: 228 }, 9, "3:1", this.black, this.white, 10);
      this.drawCircle({ x: 388, y: 326 }, 9, "2:1", this.darkGreen, this.white, 10);      
      this.drawCircle({ x: 306, y: 384 }, 9, "2:1", this.lime, this.white, 10);
      this.drawCircle({ x: 223, y: 383 }, 9, "2:1", this.brown, this.white, 10);
      this.drawCircle({ x: 108, y: 383 }, 9, "3:1", this.black, this.white, 10);
      this.drawCircle({ x: 18, y: 228 }, 9, "3:1", this.black, this.white, 10);
      this.drawCircle({ x: 18, y: 114 }, 9, "2:1", this.lime, this.white, 10);      
      this.drawCircle({ x: 74, y: 17 }, 9, "3:1", this.black, this.white, 10);
    }

    //Draw roads
    for (let key in this.props.roads) {
      this.juatanLine(
        nodeCoord[this.props.mapVersion][this.props.roads[key].node1].x,
        nodeCoord[this.props.mapVersion][this.props.roads[key].node1].y,
        nodeCoord[this.props.mapVersion][this.props.roads[key].node1].z,
        nodeCoord[this.props.mapVersion][this.props.roads[key].node2].x,
        nodeCoord[this.props.mapVersion][this.props.roads[key].node2].y,
        nodeCoord[this.props.mapVersion][this.props.roads[key].node2].z,
        this.props.cardSummary[this.props.roads[key].ownerId].color,
        4
      );
    }    

    //Draw legend
    ctx.lineWidth = 1;
    ctx.font = "12px Arial";
    this.drawCircle({ x: -70, y: 395 }, 7, "", this.gold, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Trigo", 25, 20);
    this.drawCircle({ x: -70, y: 375 }, 7, "", this.lime, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Lana", 25, 40);
    this.drawCircle({ x: -70, y: 355 }, 7, "", this.darkGreen, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Madera", 25, 60);
    this.drawCircle({ x: -70, y: 335 }, 7, "", this.brown, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Arcilla", 25, 80);
    this.drawCircle({ x: -70, y: 315 }, 7, "", this.grey, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Piedra", 25, 100);
    this.drawCircle({ x: -70, y: 295 }, 7, "", this.khaki, this.white, 12);
    ctx.fillStyle = "#000000";
    ctx.textAlign = "left";
    ctx.fillText("Desierto", 25, 120);

    //Draw nodeIds
    if (this.props.showNodeIds) {
      this.juatanNodeIds();
    }

    //Draw towns
    for (let key in this.props.towns) {
      this.juatanTown(key);
    }

    //Draw dices
    if (this.props.game.dice1 != 0){
      this.roundRect(ctx, this.xOffset(390), this.yOffset(390), 40, 40, 5, true);
      if (this.props.game.dice1 == 1){
        this.drawCircle({ x: 410, y: 370 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice1 == 2){
        this.drawCircle({ x: 400, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice1 == 3){
        this.drawCircle({ x: 400, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 410, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice1 == 4){
        this.drawCircle({ x: 400, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 400, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice1 == 5){
        this.drawCircle({ x: 400, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 400, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 410, y: 370 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice1 == 6){
        this.drawCircle({ x: 400, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 400, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 400, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 420, y: 360 }, 3, "", this.black, this.white);
      }      
    }
    if (this.props.game.dice2 != 0){
      this.roundRect(ctx, this.xOffset(440), this.yOffset(390), 40, 40, 5, true);
      if (this.props.game.dice2 == 1){
        this.drawCircle({ x: 460, y: 370 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice2 == 2){
        this.drawCircle({ x: 450, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice2 == 3){
        this.drawCircle({ x: 450, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 460, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice2 == 4){
        this.drawCircle({ x: 450, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 450, y: 360 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice2 == 5){
        this.drawCircle({ x: 450, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 450, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 460, y: 370 }, 3, "", this.black, this.white);
      }else if (this.props.game.dice2 == 6){
        this.drawCircle({ x: 450, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 450, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 450, y: 360 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 380 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 370 }, 3, "", this.black, this.white);
        this.drawCircle({ x: 470, y: 360 }, 3, "", this.black, this.white);
      }      
    }

    //Draw item being placed    
    if (this.props.showEditMapPanel == true){
      this.counter++;
      if (this.counter % 2 == 0){
        if (this.props.editMapPanelData.user >= 0){
          if (this.props.editMapPanelData.type == "ro"){
            if (this.props.editMapPanelData.node1 > 0 && this.props.editMapPanelData.node2 > 0){
              if (this.props.editMapPanelData.user == 0){
                this.juatanLine(
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].x,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].y,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].z,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].x,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].y,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].z,
                  "white",
                  4
                );
              }else{
                this.juatanLine(
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].x,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].y,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node1].z,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].x,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].y,
                  nodeCoord[this.props.mapVersion][this.props.editMapPanelData.node2].z,
                  this.props.cardSummary[this.props.editMapPanelData.user].color,
                  4
                );
              }
            }
          }else if (this.props.editMapPanelData.type == "tow"){
            if (this.props.editMapPanelData.node1 > 0){
              this.juatanTown(this.props.editMapPanelData.node1,this.props.editMapPanelData.user,1);
            }
          }else if (this.props.editMapPanelData.type == "ci"){
            if (this.props.editMapPanelData.node1 > 0){
              this.juatanTown(this.props.editMapPanelData.node1,this.props.editMapPanelData.user,2);
            }
          }
        }
      }
    }else if (this.props.showMoveThiefPanel){
      this.counter++;
      if (this.counter % 2 == 0){
        if (this.props.thiefTerrain > 0){
          let pc = this.convert(terrainNodes[this.props.mapVersion][this.props.thiefTerrain]["x"],
                                terrainNodes[this.props.mapVersion][this.props.thiefTerrain]["y"],
                                terrainNodes[this.props.mapVersion][this.props.thiefTerrain]["z"]);        
          this.drawCircle(
            pc,
            13,
            "",
            this.paleGoldenRod,
            "white",
            10,
            true
            );
        }
      }
    }
  }

  
  getMousePos(evt) {
    var rect = this.refs["juatanCanvas"].getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }

  evaluateMouseClick(evt){
    if (this.props.showEditMapPanel){
      const cursorRelativePos = this.getMousePos(evt);
      const cursorRelativePosXScaled = Math.round(cursorRelativePos.x * this.refs["juatanCanvas"].width) / this.refs["juatanCanvas"].scrollWidth;
      const cursorRelativePosYScaled = Math.round(cursorRelativePos.y * this.refs["juatanCanvas"].height) / this.refs["juatanCanvas"].scrollHeight;
      this.parseMouseForMap(cursorRelativePosXScaled,cursorRelativePosYScaled);
    }else if (this.props.showMoveThiefPanel){
      const cursorRelativePos = this.getMousePos(evt);
      const cursorRelativePosXScaled = Math.round(cursorRelativePos.x * this.refs["juatanCanvas"].width) / this.refs["juatanCanvas"].scrollWidth;
      const cursorRelativePosYScaled = Math.round(cursorRelativePos.y * this.refs["juatanCanvas"].height) / this.refs["juatanCanvas"].scrollHeight;
      this.parseMouseForThief(cursorRelativePosXScaled,cursorRelativePosYScaled);
    }
  }

  parseMouseForThief(x,y){
    let minDist = -1;    
    let minId = 0;    
    let distAux;
    let p1,pc;

    
    for (let key in terrainNodes[this.props.mapVersion]) {
      pc = this.convert(
        terrainNodes[this.props.mapVersion][key].x,
        terrainNodes[this.props.mapVersion][key].y,
        terrainNodes[this.props.mapVersion][key].z
      );
      p1 = this.pOffset(pc);
      distAux = Math.pow(p1.x-x,2) + Math.pow(p1.y-y,2);
      
      if (distAux < minDist || minDist == -1){
        minDist = distAux;
        minId = Number(key);
      }
    }
    this.props.updateThiefTerrain(minId);
  }

  parseMouseForMap(x,y){
    let minDist1 = -1;
    let minDist2 = -1;
    let minId1 = 0;
    let minId2 = 0;
    let distAux;
    let p1,pc;

    
    for (let key in nodeCoord[this.props.mapVersion]) {
      pc = this.convert(
        nodeCoord[this.props.mapVersion][key].x,
        nodeCoord[this.props.mapVersion][key].y,
        nodeCoord[this.props.mapVersion][key].z
      );
      p1 = this.pOffset(pc);
      distAux = Math.pow(p1.x-x,2) + Math.pow(p1.y-y,2);
      
      if (distAux < minDist1 || minDist1 == -1){
        minDist2 = minDist1;
        minId2 = minId1;
        minDist1 = distAux;
        minId1 = Number(key);
      }else if (distAux < minDist2){
        minDist2 = distAux;
        minId2 = Number(key);
      }
    }

    let newEditMapPanelData = JSON.parse(JSON.stringify(this.props.editMapPanelData));
    newEditMapPanelData["node1"] = minId1;
    newEditMapPanelData["node2"] = minId2;
    this.props.updateEditMapPanelData(newEditMapPanelData);
  }

  roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'black';
    ctx.fillStyle = 'white';
    if (typeof stroke === 'undefined') {
      stroke = true;
    }
    if (typeof radius === 'undefined') {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
      var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  
  }

  juatanNodeIds() {
    for (let key in nodeCoord[this.props.mapVersion]) {
      let pc = this.convert(
        nodeCoord[this.props.mapVersion][key].x,
        nodeCoord[this.props.mapVersion][key].y,
        nodeCoord[this.props.mapVersion][key].z
      );
      this.drawCircle(pc, 12, key, this.white, this.red, 12);
    }

    for (let key in terrainNodes[this.props.mapVersion]) {
      let pc = this.convert(
        terrainNodes[this.props.mapVersion][key].x,
        terrainNodes[this.props.mapVersion][key].y,
        terrainNodes[this.props.mapVersion][key].z
      );
      this.drawCircle(
        pc,
        15,
        key,
        this.white,
        "red",
        20,
        this.props.game.thiefLocation == key
      );
    }
  }

  juatanTown(key, owner = -1, level = -1) {
    let ctx = this.refs["juatanCanvas"].getContext("2d");
    let pc = this.convert(
      nodeCoord[this.props.mapVersion][key].x,
      nodeCoord[this.props.mapVersion][key].y,
      nodeCoord[this.props.mapVersion][key].z
    );

    let pco = this.pOffset(pc);

    ctx.beginPath();
    ctx.lineWidth = 1;

    ctx.strokeStyle = this.black;

    if (owner == -1){
      ctx.fillStyle = this.props.cardSummary[this.props.towns[key].ownerId].color;
    }else if (owner == 0){
      ctx.fillStyle = "white";
    }else{
      ctx.fillStyle = this.props.cardSummary[this.props.editMapPanelData.user].color;
    }

    ctx.arc(pco.x, pco.y, 10, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();

    /*if (this.props.cardSummary[this.props.towns[key].ownerId].color == 'blue'
        || this.props.cardSummary[this.props.towns[key].ownerId].color == 'green'){
      ctx.strokeStyle = 'white';
      ctx.fillStyle = 'white';
    }else{*/
    ctx.strokeStyle = this.black;
    ctx.fillStyle = this.black;
    //}

    ctx.lineWidth = 2;

    if (level == -1){
      level = this.props.towns[key].level;
    }

    if (level == 1 && owner != 0) {
      ctx.beginPath();
      ctx.moveTo(pco.x + 4, pco.y + 4);
      ctx.lineTo(pco.x + 4, pco.y - 2);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x + 7, pco.y);
      ctx.lineTo(pco.x, pco.y - 5);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 4, pco.y + 4);
      ctx.lineTo(pco.x - 4, pco.y - 2);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 7, pco.y);
      ctx.lineTo(pco.x, pco.y - 5);
      ctx.stroke();
    } else if (level == 2 && owner != 0) {
      ctx.beginPath();
      ctx.moveTo(pco.x + 4, pco.y + 6);
      ctx.lineTo(pco.x + 4, pco.y - 6);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x + 4, pco.y - 6);
      ctx.lineTo(pco.x - 4, pco.y - 6);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 4, pco.y + 6);
      ctx.lineTo(pco.x - 4, pco.y - 6);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 6, pco.y + 6);
      ctx.lineTo(pco.x + 6, pco.y + 6);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 2, pco.y + 2);
      ctx.lineTo(pco.x + 2, pco.y + 2);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(pco.x - 2, pco.y - 2);
      ctx.lineTo(pco.x + 2, pco.y - 2);
      ctx.stroke();
    }
  }

  juatanLine(x1, y1, z1, x2, y2, z2, color, width) {
    let p1 = this.convert(x1, y1, z1);
    let p2 = this.convert(x2, y2, z2);
    this.drawLine(p1, p2, color, width);
  }

  juatanHexagon(x, y, z, color, diceNumber, blank, thief) {
    let ctx = this.refs["juatanCanvas"].getContext("2d");
    let pc = this.convert(x, y, z);
    let pco = this.pOffset(pc);

    let size;
    if (blank) {
      size = this.props.hexaHeight + 7;
    } else {
      size = this.props.hexaHeight;
    }
    ctx.beginPath();
    ctx.moveTo(pco.x + size * Math.sin(0), pco.y + size * Math.cos(0));
    for (let side = 0; side < 7; side++) {
      ctx.lineTo(
        pco.x + size * Math.sin((side * Math.PI) / 3),
        pco.y + size * Math.cos((side * Math.PI) / 3)
      );
    }

    if (blank) {
      ctx.fillStyle = this.white;
    } else {
      ctx.fillStyle = color;
    }

    ctx.fill();

    //if (diceNumber != 0) {
    this.drawCircle(
      pc,
      13,
      diceNumber,
      this.paleGoldenRod,
      this.getDiceColor(diceNumber),
      this.getDiceFontSize(diceNumber),
      thief
    );
    //}
  }

  getDiceFontSize(diceNumber) {
    switch (diceNumber) {
      case 2:
      case 12:
        return 10;
      case 3:
      case 11:
        return 13;
      case 4:
      case 10:
        return 16;
      case 5:
      case 9:
        return 19;
      case 6:
      case 8:
        return 22;
    }
  }
  getDiceColor(diceNumber) {
    if (diceNumber == 6 || diceNumber == 8) {
      return this.red;
    }
    return this.black;
  }

  drawCircle(p1, r, text, backColor, color, fontSize, thief = false) {
    let ctx = this.refs["juatanCanvas"].getContext("2d");

    p1 = this.pOffset(p1);

    ctx.beginPath();
    ctx.lineWidth = 1;

    ctx.strokeStyle = backColor;
    ctx.fillStyle = backColor;

    ctx.arc(p1.x, p1.y, r, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();

    ctx.font = fontSize + "px Arial";
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.textAlign = "center";
    ctx.fillText(text, p1.x, p1.y + r / 2 - this.getFontOffSet(fontSize));

    if (thief == true) {
      ctx.strokeStyle = this.black;
      ctx.fillStyle = this.black;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(p1.x - (Math.sqrt(2) / 2) * r, p1.y - (Math.sqrt(2) / 2) * r);
      ctx.lineTo(p1.x + (Math.sqrt(2) / 2) * r, p1.y + (Math.sqrt(2) / 2) * r);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(p1.x - (Math.sqrt(2) / 2) * r, p1.y + (Math.sqrt(2) / 2) * r);
      ctx.lineTo(p1.x + (Math.sqrt(2) / 2) * r, p1.y - (Math.sqrt(2) / 2) * r);
      ctx.stroke();
    }
  }

  getFontOffSet(fontSize) {
    if (fontSize >= 20) {
      return -1;
    } else if (fontSize >= 15) {
      return 0;
    } else if (fontSize >= 12) {
      return 2;
    } else if (fontSize >= 10) {
      return 3;
    }
  }

  convert(x, y, z) {
    let uy = (this.props.hexaHeight * 2) / Math.sqrt(3);
    let uz = this.props.hexaHeight / Math.sqrt(3);
    return { x: x * this.props.hexaHeight, y: y * uy + z * uz };
  }

  getColor(code) {
    switch (code) {
      case "gr":
        return this.gold;
      case "wo":
        return this.lime;
      case "ti":
        return this.darkGreen;
      case "ti":
        return this.gold;
      case "cl":
        return this.brown;
      case "st":
        return this.grey;
      case "de":
        return this.khaki;
      default:
        return this.white;
    }
  }

  render() {
    const canvasDisplay = (
      <canvas
        ref={"juatanCanvas"}
        width={this.canvasWidth}
        height={this.canvasHeight}
        onClick={(evt) => this.evaluateMouseClick(evt)}
      />
    );
//aqui
    return <Container id="wholeCanvas">{canvasDisplay}</Container>;
  }
}
