import React, { Component } from "react";
import { Popup, Icon as IconSem, Button, Image } from "semantic-ui-react";

import Icon from "react-icons-kit";
import { ic_add_a_photo } from "react-icons-kit/md/ic_add_a_photo";
import { lock } from "react-icons-kit/fa/lock";
import { u1F33E as grIcon } from "react-icons-kit/noto_emoji_regular/u1F33E";
import { u1F40F as woIcon } from "react-icons-kit/noto_emoji_regular/u1F40F";
import { u1F332 as tiIcon } from "react-icons-kit/noto_emoji_regular/u1F332";
import { u1F4D7 as clIcon } from "react-icons-kit/noto_emoji_regular/u1F4D7";
import { cubes as stIcon } from "react-icons-kit/fa/cubes";
import { star as viIcon } from "react-icons-kit/fa/star";
import { user_square as knIcon } from "react-icons-kit/ikons/user_square";
import { u1F528 as coIcon } from "react-icons-kit/noto_emoji_regular/u1F528";

export default class GameStatus extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../tools/tools");
    this.state = {};
    this.reloadState = this.reloadState.bind(this);
    this.finishTurn = this.finishTurn.bind(this);

    this.phaseName = ["Dados", "Recursos", "Comercio/Const", "Fase extra"];
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    this.setState(newState);
  }

  finishTurn() {
    let confirmAction = window.confirm("¿Desea pasar el turno?");
    if (confirmAction) {
      this.props.makeRequest({ reqType: "finishTurn" });
    }
  }

  render() {
    let now = new Date();
    let timeString;
    if (
      this.props.data.game.paused == 1 ||
      this.props.data.game.turnPhase == 1 ||
      this.props.data.game.turnPhase == 3
    ) {
      timeString = this.tools.getHHMMSS(this.props.data.game.turnTime);
    } else {
      timeString = this.tools.getHHMMSS(
        this.props.data.game.turnTime +
          Math.floor(now.getTime() / 1000) -
          this.props.data.game.turnUnix
      );
    }

    let enableThrowDices = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.turnPhase == 0
    ) {
      enableThrowDices = true;
    }

    let enableMakeOffer = false;
    let enableSeeOffer = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.turnPhase == 2 &&
      this.props.data.game.purchaseDone == 0
    ) {
      if (this.props.data.game.o == 0) {
        enableMakeOffer = true;
      } else {
        enableSeeOffer = true;
      }
    }

    let enableEvaluateOffer = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) !=
        this.props.userId &&
      this.props.data.game.turnPhase == 2 &&
      this.props.data.game.o == 1 &&
      this.props.data.game.purchaseDone == 0
    ) {
      for (let i = 1; i < 7; i++) {
        if (
          this.props.data.game["op" + i] == 1 &&
          this.props.data.game["p" + i] == this.props.userId
        ) {
          enableEvaluateOffer = true;
        }
      }
    }

    let enableBuild = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.turnPhase == 2
    ) {
      enableBuild = true;
    } else if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) !=
        this.props.userId &&
      this.props.data.game.turnPhase == 3 &&
      this.props.data.game.nPlayers > 4 &&
      this.props.data.game.nextPurchaseUser == this.props.userId
    ) {
      enableBuild = true;
    }

    let enableFinishTurn = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.turnPhase == 2
    ) {
      enableFinishTurn = true;
    }

    let enableUseCard = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.turnPhase < 3 &&
      this.props.data.game.cardUsed == 0
    ) {
      if (
        this.props.data.cards[this.props.userId].kn_c > 0 ||
        this.props.data.cards[this.props.userId].co > 0 ||
        this.props.data.cards[this.props.userId].mo > 0 ||
        this.props.data.cards[this.props.userId].di > 0
      ) {
        enableUseCard = true;
      } else {
        enableUseCard = false;
      }
    }

    let myTurn = 0;
    for (let i = 1; i < 7; i++) {
      if (this.props.data.game["p" + i] == this.props.userId) {
        myTurn = i;
      }
    }

    let enableRequestShopping = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) !=
        this.props.userId &&
      this.props.data.game.turnPhase < 3 &&
      this.props.data.game["buyRequest" + myTurn] == 0 &&
      this.props.data.game.nPlayers > 4
    ) {
      enableRequestShopping = true;
    }

    let enableCancelShopping = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) !=
        this.props.userId &&
      this.props.data.game["buyRequest" + myTurn] == 1 &&
      this.props.data.game.nPlayers > 4
    ) {
      enableCancelShopping = true;
    }

    let enableMoveThief = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.thiefMoved == 1 &&
      this.props.showMoveThiefPanel == false
    ) {
      enableMoveThief = true;
    }

    let enableUseThief = false;
    if (
      Number(this.props.data.game["p" + this.props.data.game.turnOwner]) ==
        this.props.userId &&
      this.props.data.game.thiefUsed == 1
    ) {
      enableUseThief = true;
    }

    let enableClaimVictory = false;            
    if (      
      this.props.data.cardSummary[this.props.userId].points + this.props.data.cards[this.props.userId].vi >= 10
      && this.props.data.game.winner == 0
    ) {
      enableClaimVictory = true;
    }

    let enableShowVictory = false;
    if (      
      this.props.data.game.winner != 0
    ) {
      enableShowVictory = true;
    }

    if (this.props.data.game.thiefMoved == 1 || this.props.data.game.thiefUsed == 1) {
      enableThrowDices = false;
      enableMakeOffer = false;
      enableSeeOffer = false;
      enableEvaluateOffer = false;
      enableBuild = false;
      enableFinishTurn = false;
      enableRequestShopping = false;
      enableCancelShopping = false;
      enableUseCard = false;      
    }    

    if (
      this.props.data.game.paused == 1 || 
      this.props.data.game.initialPlacement != 2 ||
      this.props.data.game.winner != 0 
    ) {
      enableThrowDices = false;
      enableMakeOffer = false;
      enableSeeOffer = false;
      enableEvaluateOffer = false;
      enableBuild = false;
      enableFinishTurn = false;
      enableRequestShopping = false;
      enableCancelShopping = false;
      enableUseCard = false;
      enableUseThief = false;
      enableMoveThief = false;      
    }    

    let costCard = (<Image src="/images/costs.jpg" size="big" rounded />);

    return (
      <div>
        <div style={{ display: "inline-block", width: "15%" }}>
          <Popup
            content="Tiempo del turno actual"
            trigger={
              <div>
                <IconSem name="clock" size="small" />
                <p style={{ fontSize: "14px", display: "inline-block" }}>
                  {timeString}
                </p>
              </div>
            }
          />

          <Popup
            content="Fase del turno"
            trigger={
              <div>
                <IconSem name="list ol" size="small" />
                <p style={{ fontSize: "14px", display: "inline-block" }}>
                  {this.phaseName[this.props.data.game.turnPhase]}
                </p>
              </div>
            }
          />

          <Popup
            content="A quién estamos esperando"
            trigger={
              <div>
                <IconSem name="hand point right" size="small" />
                <p style={{ fontSize: "14px", display: "inline-block" }}>
                  {this.props.data.game.waitingUser}
                </p>
              </div>
            }
          />
        </div>
        <div style={{ display: "inline-block", width: "30%" }}>
          <Popup
            content="Trigo"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={grIcon} size={22} style={{ color: "gold" }} />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.gr}</div>
              </div>
            }
          />
          <Popup
            content="Lana"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={woIcon} size={22} style={{ color: "lime" }} />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.wo}</div>
              </div>
            }
          />
          <Popup
            content="Madera"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon
                    icon={tiIcon}
                    size={22}
                    style={{ color: "darkgreen" }}
                  />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.ti}</div>
              </div>
            }
          />
          <Popup
            content="Arcilla"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={clIcon} size={22} style={{ color: "brown" }} />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.cl}</div>
              </div>
            }
          />
          <Popup
            content="Piedra"
            trigger={
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <div>
                  <Icon icon={stIcon} size={22} style={{ color: "grey" }} />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.st}</div>
              </div>
            }
          />
          <Popup
            content="Caballeros tapados"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={knIcon} size={22} />
                </div>
                <div style={{ textAlign: "center" }}>
                  {this.props.card.kn_c}
                  {this.props.card.kn_c_0 > 0 && (
                    <span style={{ color: "red" }}>
                      +{this.props.card.kn_c_0}
                    </span>
                  )}
                </div>
              </div>
            }
          />
          <Popup
            content="Construcción: Construye 2 carreteras gratis"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={coIcon} size={22} />
                </div>
                <div style={{ textAlign: "center" }}>
                  {this.props.card.co}
                  {this.props.card.co_0 > 0 && (
                    <span style={{ color: "red" }}>
                      +{this.props.card.co_0}
                    </span>
                  )}
                </div>
              </div>
            }
          />
          <Popup
            content="Monopolio: Elige una materia y todos los jugadores te daran todas sus cartas de esa materia prima"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <IconSem name="gavel" size="large" />
                </div>
                <div style={{ textAlign: "center" }}>
                  {this.props.card.mo}
                  {this.props.card.mo_0 > 0 && (
                    <span style={{ color: "red" }}>
                      +{this.props.card.mo_0}
                    </span>
                  )}
                </div>
              </div>
            }
          />
          <Popup
            content="Descubrimiento: Obten 2 materias primas de tu elección"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <IconSem name="magnify" size="large" />
                </div>
                <div style={{ textAlign: "center" }}>
                  {this.props.card.di}
                  {this.props.card.di_0 > 0 && (
                    <span style={{ color: "red" }}>
                      +{this.props.card.di_0}
                    </span>
                  )}
                </div>
              </div>
            }
          />
          <Popup
            content="Cartas de puntos de victoria"
            trigger={
              <div style={{ display: "inline-block" }}>
                <div>
                  <Icon icon={viIcon} size={22} />
                </div>
                <div style={{ textAlign: "center" }}>{this.props.card.vi}</div>
              </div>
            }
          />
        </div>
        <div style={{ display: "inline-block", width: "55%" }}>
          <div style={{ marginBottom: "3px" }}>
            <Popup              
              content={costCard}
              trigger={<IconSem size="large" color="grey" name="question circle" />}
            />
            {this.props.data.game.winner == 0 && (
              <Button
              size="tiny"
              color={this.props.showEditMapPanel ? "green" : "grey"}
              icon="chess board"
              content="Mapa"
              onClick={() => {
                this.props.toggleEditMap();
              }}
            />

            )}
            
            {this.props.isAdmin && this.props.usingAdmin == 1 && this.props.data.game.winner == 0 && (
              <Button
                size="tiny"
                color={this.props.data.game.turnPhase == 1 ? "green" : "grey"}
                icon="shopping basket"
                content="Recursos"
                onClick={() => {
                  this.props.setEnable({ showGiveResourcesModal: true });
                }}
              />
            )}
            {this.props.data.game.paused == 0 && this.props.data.game.initialPlacement == 2 && this.props.data.game.winner == 0 && (
              <Button
                size="tiny"
                style={{ float: "right" }}
                color="grey"
                icon="pause"
                content="Pausar"
                onClick={() => {
                  this.props.makeRequest({ reqType: "pauseGame" });
                }}
              />
            )}
            {this.props.data.game.paused == 1 && this.props.data.game.initialPlacement == 2 && this.props.data.game.winner == 0 && (
              <Button
                size="tiny"
                style={{ float: "right" }}
                color="grey"
                icon="play"
                content="Reanudar"
                onClick={() => {
                  this.props.makeRequest({ reqType: "resumeGame" });
                }}
              />
            )}
            {this.props.isAdmin && this.props.data.game.paused == 1 &&(
              <Button
                size="tiny"
                style={{ float: "right" }}
                color="black"
                icon="redo"
                content="Crear partida"
                onClick={() => {
                  this.props.setEnable({ showCreateGameModal: true });
                }}
              />
            )}
          </div>
          <div>
            {enableClaimVictory && (
              <Button
                size="tiny"
                color="green"
                icon="star"
                content="Proclamar victoria"
                onClick={() => {
                  this.props.makeRequest({ reqType: "claimVictory" });
                }}
              />
            )}
            {enableShowVictory && (
              <Button
                size="tiny"
                color="green"
                icon="star"
                content="Ver victoria"
                onClick={() => {
                  this.props.setShowVictoryModal(true);
                }}
              />
            )}            
            {enableRequestShopping && (
              <Button
                size="tiny"
                color="green"
                icon="shopping bag"
                content="Solicitar fase extra"
                onClick={() => {
                  this.props.makeRequest({ reqType: "requestShopping" });
                }}
              />
            )}
            {enableCancelShopping && (
              <Button
                size="tiny"
                color="grey"
                icon="cancel"
                content="Cancelar fase extra"
                onClick={() => {
                  this.props.makeRequest({ reqType: "cancelShopping" });
                }}
              />
            )}

            {enableThrowDices && (
              <Button
                size="tiny"
                color="purple"
                icon="cube"
                content="Tirar dados"
                onClick={() => {
                  this.props.makeRequest({ reqType: "throwDices" });
                }}
              />
            )}
            {enableMakeOffer && (
              <Button
                size="tiny"
                color="orange"
                icon="handshake"
                content="Realizar oferta"
                onClick={() => {
                  this.props.setEnable({ showMakeOfferModal: true });
                }}
              />
            )}
            {enableSeeOffer && (
              <Button
                size="tiny"
                color="orange"
                icon="handshake"
                content="Ver mi oferta"
                onClick={() => {
                  this.props.setEnable({ showSeeOfferModal: true });
                }}
              />
            )}
            {enableEvaluateOffer && (
              <Button
                size="tiny"
                color="orange"
                icon="handshake"
                content="Ver ofertas"
                onClick={() => {
                  this.props.setEnable({ showEvaluateOfferModal: true });
                }}
              />
            )}
            {enableBuild && (
              <Button
                size="tiny"
                color="brown"
                icon="shopping bag"
                content="Comprar"
                onClick={() => {
                  this.props.setEnable({ showBuyModal: true });
                }}
              />
            )}
            {enableUseCard && (
              <Button
                size="tiny"
                color="yellow"
                icon="window restore"
                content="Usar carta"
                onClick={() => {
                  this.props.setEnable({ showUseCardModal: true });
                }}
              />
            )}
            {enableMoveThief && (
              <Button
                size="tiny"
                color="blue"
                icon="spy"
                content="Mover ladrón"
                onClick={() => {
                  this.props.setEnable({ showMoveThiefPanel: true });
                }}
              />
            )}
            {enableUseThief && (
              <Button
                size="tiny"
                color="blue"
                icon="spy"
                content="Robar"
                onClick={() => {
                  this.props.setEnable({ showUseThiefModal: true });
                }}
              />
            )}
            {enableFinishTurn && (
              <Button
                size="tiny"
                color="black"
                icon="window close"
                content="Pasar turno"
                onClick={() => {
                  this.finishTurn();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
