import React, { Component } from "react";
import { Container, Button, Radio, Modal, Dropdown } from "semantic-ui-react";

export default class UseCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardChecked: "",
      resourceChecked: ""
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelector = this.handleChangeSelector.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      // No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    //let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    this.setState({
      cardChecked: "",
      resourceChecked: ""
    });
  }

  handleChange(value) {    
    this.setState({ cardChecked: value, resourceChecked: '' });    
  }

  handleChangeSelector(e,data) {    
    this.setState({ resourceChecked: data.value });    
  }

  validate() {    
  }

  handleSave() {
    //make request
    if (this.state.cardChecked != "" && (this.state.cardChecked != "mo" || this.state.resourceChecked != '')) {

      let args = {};
      args["reqType"] = "useCard";
      args["data"] = {};
      args["data"]["card"] = this.state.cardChecked;
      args["data"]["resource"] = this.state.resourceChecked;      
      this.props.makeRequest(args);      
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  render() {
    let items = [];
    let cardNames = [
      "Caballero",
      "Const.carretera",
      "Monopolio",
      "Descubrimiento"
    ];
    let cardCodes = ["kn_c", "co", "mo", "di"];
    let resourcesName = ["Trigo", "Lana", "Madera", "Arcilla", "Piedra"];
    let resourcesCode = ["gr", "wo", "ti", "cl", "st"];
    let resourceOptions = [];
    for (let i = 0; i < 5; i++) {
      resourceOptions[i] = {
        key: i,
        text: resourcesName[i],
        value: resourcesCode[i]
      };
    }

    for (let i = 0; i < 4; i++) {
      if (this.props.data.cards[this.props.userId][cardCodes[i]])
        items[i] = (
          <div>
            <Radio
              checked={this.state.cardChecked == cardCodes[i]}
              id={i}
              label={cardNames[i]}
              onChange={() => this.handleChange(cardCodes[i])}
            />
            {this.state.cardChecked == "mo" && i == 2 && (
              <Dropdown
                placeholder="Selecciona un recurso"
                selection
                options={resourceOptions}
                onChange={this.handleChangeSelector}
              />
            )}
          </div>
        );
    }

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Usar carta"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Elige que carta quieres usar:
              {items}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              labelPosition="right"
              icon="close"
              content="Volver"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Gastar carta"
              onClick={() => this.handleSave()}
              disabled={this.state.cardChecked == "" || (this.state.cardChecked == 'mo' && this.state.resourceChecked == '')}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
