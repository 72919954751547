import React, { Component } from "react";
import { Container, Button, Grid, Modal, Checkbox } from "semantic-ui-react";

export default class ThiefModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,
      userStolen: -1
    };
    this.reloadState = this.reloadState.bind(this);    
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);
    this.setStolen = this.setStolen.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newResourcesGiven = this.state.resourcesGiven;

    this.setState({
      userStolen: -1,
      validData: false,
    });
  }

  validate() {
    return this.state.validData;
  }

  handleSave() {
    //make request
    if (this.validate()) {
      let args = {};
      args["reqType"] = "useThief";
      args["data"] = this.state.userStolen;      
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  setStolen(i) {
    this.setState({ 
      userStolen: i,
      validData: true,
    });

  }

  render() {
    let userItems = [];    
    for (let i = 1; i < 7; i++) {
      if (
        this.props.data.game["p" + i] != -1 && 
        this.props.data.game["p" + i] != this.props.userId &&
        this.props.data.game["thief" + i] == 1
      ) {
        userItems[i] = (
          <div>
            <Checkbox
              checked={this.state.userStolen == this.props.data.game["p" + i]}
              id={'u'+i}
              label={
                this.props.data.users[this.props.data.game["p" + i]]["username"]
              }
              onChange={() => this.setStolen(Number(this.props.data.game["p" + i]))}
            />
          </div>
        );
      }
    }

    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Robar una carta"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div>
                <p>¿A que jugador le quieres robar una carta?</p>
                <div>{userItems}</div>
              </div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Cancelar"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Robar"
              onClick={() => this.handleSave()}
              disabled={!this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
