/* @flow */
import React, { Component } from "react";
//import { Card, Icon, Popup, Grid } from "semantic-ui-react";
import TurnPanelCard from './TurnPanelCard';

export default class TurnPanel extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../../tools/tools.js");
  }

  render() {
    const ColoredLine = () => (
      <hr
        style={{
          color: "black",
          backgroundColor: "black",
          height: 1
        }}
      />
    );

    let cardItems = [];
    for (let i=1;i<7;i++){
      if (this.props.data.game['p'+i] != -1){
      cardItems[i] = (
        <TurnPanelCard
          user={this.props.data.users[this.props.data.game['p'+i]]}
          color={this.props.data.cardSummary[this.props.data.game['p'+i]].color}
          game={this.props.data.game}
          cardSummary={this.props.data.cardSummary[this.props.data.game['p'+i]]}
          showDice={this.props.data.game.turnOwner == i?true:false}
          army={this.props.data.game.armyOwner == i}
          route={this.props.data.game.routeOwner == i}
          dices={this.props.data.dices}
        />
      );    
      }  
    }

    return (
      <div>
        {cardItems}
      </div>
    );
  }
}
