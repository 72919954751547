import React, { Component } from "react";
import { Container, Button, Input, Modal, Checkbox } from "semantic-ui-react";

export default class EditMapPanel extends Component {
  constructor(props) {
    super(props);
    
    this.reloadState = this.reloadState.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setType = this.setType.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }*/

  reloadState(receivedProps) {
    //let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    //let newResourcesGiven = this.state.resourcesGiven;

    /*this.setState({
      validData: false,
      user: this.props.userId,
      type: "",
      node1: "",
      node2: "",
    });*/

    let editMapPanelData = {};
    editMapPanelData["user"] = this.props.userId;
    editMapPanelData["type"] = "";
    editMapPanelData["node1"] = "";
    editMapPanelData["node2"] = "";
    this.props.updateEditMapPanelData(editMapPanelData);

  }

  validate() {
    return true;
  }

  handleSave() {
    //make request
    if (this.validate()) {
      let args = {};
      args["reqType"] = "editMap";
      args["data"] = {};
      args["data"]["user"] = this.props.editMapPanelData.user;
      args["data"]["type"] = this.props.editMapPanelData.type;
      args["data"]["node1"] = this.props.editMapPanelData.node1;
      args["data"]["node2"] = this.props.editMapPanelData.node2;
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  setUser(i) {
    let newEditMapPanelData = JSON.parse(JSON.stringify(this.props.editMapPanelData));
    newEditMapPanelData["user"] = i;
    this.props.updateEditMapPanelData(newEditMapPanelData);
  }
  setType(i) {
    let newEditMapPanelData = JSON.parse(JSON.stringify(this.props.editMapPanelData));
    newEditMapPanelData["type"] = i;
    this.props.updateEditMapPanelData(newEditMapPanelData);
  }

  handleChange(e, data) {    
    if (Number.isInteger(Number(data.value))) {
      let newEditMapPanelData = JSON.parse(JSON.stringify(this.props.editMapPanelData));
      newEditMapPanelData[data.name] = Number(data.value);
      this.props.updateEditMapPanelData(newEditMapPanelData);
    }
  }

  render() {
    let userItems = [];
    if (this.props.isAdmin && this.props.usingAdmin == 1) {
      userItems[0] = (
        <div>
          <Checkbox
            checked={this.props.editMapPanelData.user == 0}
            id={"u0"}
            label={"Eliminar"}
            onChange={() => this.setUser(0)}
          />
        </div>
      );
    }
    for (let i = 1; i < 7; i++) {
      if (this.props.data.game["p" + i] != -1) {
        if (
          (this.props.isAdmin && this.props.usingAdmin == 1) ||
          this.props.userId == this.props.data.game["p" + i]
        ) {
          userItems[i] = (
            <div>
              <Checkbox
                checked={this.props.editMapPanelData.user == this.props.data.game["p" + i]}
                id={"u" + i}
                label={
                  this.props.data.users[this.props.data.game["p" + i]][
                    "username"
                  ]
                }
                onChange={() =>
                  this.setUser(Number(this.props.data.game["p" + i]))
                }
              />
            </div>
          );
        }
      }
    }

    let typeItems = [];
    typeItems[0] = (
      <div>
        <Checkbox
          checked={this.props.editMapPanelData.type == "ro"}
          id={"t0"}
          label={"Carretera"}
          onChange={() => this.setType("ro")}
        />
      </div>
    );
    typeItems[1] = (
      <div>
        <Checkbox
          checked={this.props.editMapPanelData.type == "tow"}
          id={"t1"}
          label={"Poblado"}
          onChange={() => this.setType("tow")}
        />
      </div>
    );
    typeItems[2] = (
      <div>
        <Checkbox
          checked={this.props.editMapPanelData.type == "ci"}
          id={"t2"}
          label={"Ciudad"}
          onChange={() => this.setType("ci")}
        />
      </div>
    );

    return (
      <div>
        <div>
          <h4 style={{ display: "inline-block", width: "60%" }}>
            Editar el mapa
          </h4>
          <Button
            size="tiny"
            color={this.props.showNodeIds ? "green" : "grey"}
            icon="hashtag"
            content="IDs"
            onClick={() => {
              this.props.toggleNodeIds();
            }}
          />
        </div>
        <div>
          <div>¿Que jugador?</div>
          <div>{userItems}</div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <div>¿Que deseas añadir o eliminar?</div>
          <div>{typeItems}</div>
        </div>
          <div style={{ marginBottom: "10px" }}>
            {this.props.showNodeIds && (
              <Input
                style={{ marginTop: "20px" }}
                fluid
                placeholder="ID nodo 1"
                name="node1"
                value={this.props.editMapPanelData.node1}
                onChange={this.handleChange}
              />
            )}
            {this.props.showNodeIds && this.props.editMapPanelData.type == "ro" && (
              <Input
                fluid
                style={{ marginTop: "20px" }}
                placeholder="Id nodo 2"
                name="node2"
                value={this.props.editMapPanelData.node2}
                onChange={this.handleChange}
              />
            )}
        </div>
        <div>
          <Button
            negative
            labelPosition="right"
            icon="close"
            content="Cancelar"
            onClick={() => this.handleCancel()}
          />
          <Button
            positive
            labelPosition="right"
            icon="checkmark"
            content="Aplicar"
            onClick={() => this.handleSave()}
            disabled={
              this.props.editMapPanelData.user == -1 ||
              this.props.editMapPanelData.type == "" ||
              (this.props.editMapPanelData.type == "ro" &&
                (!Number.isInteger(this.props.editMapPanelData.node1) ||
                  !Number.isInteger(this.props.editMapPanelData.node2) ||
                  this.props.editMapPanelData.node1 == 0 ||
                  this.props.editMapPanelData.node2 == 0
                  )) ||
              ((this.props.editMapPanelData.type == "tow" || this.props.editMapPanelData.type == "ci") &&
                (!Number.isInteger(this.props.editMapPanelData.node1) ||
                this.props.editMapPanelData.node1 == 0)
              )
            }
          />
        </div>
      </div>
    );
  }
}
