import React, { Component } from "react";
import { Container, Button, Checkbox, Modal } from "semantic-ui-react";

export default class EvaluateOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,
      resourcesOffered : [0,0,0,0,0],
      resourcesRequested : [0,0,0,0,0],
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);

    this.resourceName = ["gr", "wo", "ti", "cl", "st"];
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {    
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    //Check if this user is offered
    newState.validData = false;
    for (let i = 1; i < 7; i++) {
      if (
        propsCopy.data.game["op" + i] == 1 &&
        propsCopy.data.game["p" + i] == propsCopy.userId
      ) {
        newState.validData = true;
      }
    }

    //Check enough resources to accept
    let resourceName = ['gr','wo','ti','cl','st'];
    for (let i = 0; i < 5; i++) {      
      if (Number(propsCopy.data.game['r' + resourceName[i]]) > 
            propsCopy.data.cards[this.props.userId][resourceName[i]]){
        newState.validData=false;
      }
    }

    //Load resources to state    
    for (let i = 0; i < 5; i++) {      
      newState.resourcesOffered[i] = Number(propsCopy.data.game['o' + resourceName[i]]);
      newState.resourcesRequested[i] = Number(propsCopy.data.game['r' + resourceName[i]]);
    }
    newState.offerNumber = propsCopy.data.game.offerNumber;    
    this.setState(newState);
  }

  handleChange(type, pos, delta = 0) {}

  validate(stateChecked) {
    return true;
  }

  handleSave() {
    //make request
    let args = {};
    args["reqType"] = "acceptOffer";
    args['data'] = this.state.offerNumber;
    this.props.makeRequest(args);
  }

  handleCancel() {
    this.props.hideModal();
  }

  render() {
    const usersOfferedItems = [];
    for (let i = 1; i < 7; i++) {
      if (
        i != this.props.data.game.turnOwner &&
        this.props.data.game["op" + i] == 1 &&
        this.props.data.game["p" + i] != -1
      ) {
        usersOfferedItems[i] = (
          <div> {this.props.users[this.props.data.game["p" + i]].username}</div>
        );
      }
    }
    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Oferta recibida"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div>
                <b>¿Quien hace la oferta?</b>
              </div>
              {
                this.props.users[
                  this.props.data.game["p" + this.props.data.game.turnOwner]
                ].username
              }
              <div>
                <b>¿A quien le hace la oferta?</b>
              </div>
              {usersOfferedItems}
            </Modal.Description>
            <hr></hr>
            <div className="resource-grid">
              <div className="resource-column-1">
                <div>
                  <b>
                    {this.props.users[
                      this.props.data.game["p" + this.props.data.game.turnOwner]
                    ].username + " da"}
                  </b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Trigo
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[0]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Lana
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[1]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Madera
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[2]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Arcilla
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[3]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Piedra
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesOffered[4]}
                  </div>
                </div>
              </div>
              <div className="resource-column-2">
                <div>
                  <b>{"Tú das"}</b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Trigo
                  </div>
                  <div
                    className={
                      this.state.resourcesRequested[0] >
                        this.props.data.cards[this.props.userId]["gr"] &&
                      "redColor"
                    }
                    style={{ display: "inline-block", width: "50px" }}
                  >
                    {this.state.resourcesRequested[0] +
                      " / " +
                      this.props.data.cards[this.props.userId]["gr"]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Lana
                  </div>
                  <div
                    className={
                      this.state.resourcesRequested[1] >
                        this.props.data.cards[this.props.userId]["wo"] &&
                      "redColor"
                    }
                    style={{ display: "inline-block", width: "50px" }}
                  >
                    {this.state.resourcesRequested[1] +
                      " / " +
                      this.props.data.cards[this.props.userId]["wo"]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Madera
                  </div>
                  <div
                    className={
                      this.state.resourcesRequested[2] >
                        this.props.data.cards[this.props.userId]["ti"] &&
                      "redColor"
                    }
                    style={{ display: "inline-block", width: "50px" }}
                  >
                    {this.state.resourcesRequested[2] +
                      " / " +
                      this.props.data.cards[this.props.userId]["ti"]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "60px" }}>
                    Arcilla
                  </div>
                  <div
                    className={
                      this.state.resourcesRequested[3] >
                        this.props.data.cards[this.props.userId]["cl"] &&
                      "redColor"
                    }
                    style={{ display: "inline-block", width: "50px" }}
                  >
                    {this.state.resourcesRequested[3] +
                      " / " +
                      this.props.data.cards[this.props.userId]["cl"]}
                  </div>
                </div>

                <div>
                  <div
                    className={
                      this.state.resourcesRequested[4] >
                        this.props.data.cards[this.props.userId]["st"] &&
                      "redColor"
                    }
                    style={{ display: "inline-block", width: "60px" }}
                  >
                    Piedra
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesRequested[4] +
                      " / " +
                      this.props.data.cards[this.props.userId]["st"]}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              labelPosition="right"
              icon="arrow left"
              content="Volver"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="handshake"
              content="Aceptar oferta"
              onClick={() => this.handleSave()}
              disabled={!this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
