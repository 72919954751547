import React, { Component } from "react";
import { Container, Icon } from "semantic-ui-react";

export default class PlayerCard extends Component {
  constructor(props) {
    super(props);
    this.matrices = require("../../tools/tools");
    this.state = {
      data: {}
    };
    this.reloadState = this.reloadState.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    newState.data = propsCopy.data;
    this.setState(newState);
  }

  handleChange(e, data) {
    //this.setState({ checkedPlayers: newCheckedPlayers });
  }

  render() {
    //debugger;
    return (
      <div>
        <div>
          <Icon name='user' size='large' color={this.props.color}/>
          <b>
            {this.props.username}
          </b>          
        </div>
        <hr></hr>
        <div className="resource-grid">
          <div className="resource-column-1">
            <div><b>Recursos</b></div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Trigo
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.gr}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Lana
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.wo}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Madera
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.ti}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Arcilla
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.cl}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Piedra
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.st}
              </div>
            </div>
            <hr></hr>
            <div><b>Cartas de desarrollo (tapadas)</b></div>            
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Caballero
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.kn_c}
                {this.props.cards.kn_c_0 > 0 && ' + ' + this.props.cards.kn_c_0}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Const.carretera
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.co}
                {this.props.cards.co_0 > 0 && ' + ' + this.props.cards.co_0}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Monopolio
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.mo}
                {this.props.cards.mo_0 > 0 && ' + ' + this.props.cards.mo_0}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Descubrimiento
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.di}
                {this.props.cards.di_0 > 0 && ' + ' + this.props.cards.di_0}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                P.victoria
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.vi}
              </div>
            </div>
            <hr></hr>            
            <div><b>Cartas de desarrollo (destapadas)</b></div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Caballeros destapados
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.kn_u}
              </div>
            </div>
          </div>
          <div className="resource-column-2">
            <div><b>Construcciones</b></div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Carreteras
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.ro}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Pueblos
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.tow}
              </div>
            </div>
            <div>
              <div style={{ display: "inline-block", width: "120px" }}>
                Ciudades
              </div>
              <div style={{ display: "inline-block", width: "50px" }}>
                {this.props.cards.ci}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}
