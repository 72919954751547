import React, { Component } from "react";
import {
  Dimmer,
  Loader,
  Image,
  Segment,
  Container,
  Grid,
  TransitionablePortal,
  Button
} from "semantic-ui-react";
import Header from "../Header/";
import ViewGame from "../ViewGame/";
import Login from "../Login";
import AlertText from "../AlertText";

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      error: "none",
      waitingResponse: true,
      data: {},
      userId: -1,
      username: "",
      isAdmin: false,
      usingAdmin: false,
      lastRefreshUnix: 0,
      alertText: "",
      showSelectGameModal: false, 
    };

    this.savingPending = false;

    this.makeRequest = this.makeRequest.bind(this);
    this.receiveRequest = this.receiveRequest.bind(this);
    this.getCookie = this.getCookie.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.closeSession = this.closeSession.bind(this);
    this.toggleUsingAdmin = this.toggleUsingAdmin.bind(this);
    this.fetchDataFromServer = this.fetchDataFromServer.bind(this);
    this.setFetchDataBlocked = this.setFetchDataBlocked.bind(this);
    this.closeAlertText = this.closeAlertText.bind(this);
    this.toggleSelectGameModal = this.toggleSelectGameModal.bind(this);

    //*****************************************************************************************************************
    this.appVersion = 7;
    this.offline = false;
    //online params:
    this.offlineButLoggedOff = false;
    this.offlineUser = "Juan";    
    
    let localApi = false;
    if (localApi == true){
      this.apiURL = "http://localhost/juatan/juatanApi.php";
    }else{
      this.apiURL = "http://juatanapi.cosasdejuan.es/juatanApi.php";
    }
    
    //*****************************************************************************************************************
    this.fetchDataPeriod = 500; //Cada cuantos milisegundos solicita refresco de datos
    //*****************************************************************************************************************
    this.fetchDataBlocked = false;
    this.onGoingRequest = false;
  }

  componentDidMount() {    
    let obj = {};

    obj["reqType"] = "firstRequest";
    this.makeRequest(obj);

    window.setInterval(this.fetchDataFromServer, this.fetchDataPeriod); //Comprueba si hay que refrescar cada X milisegundos
  }

  fetchDataFromServer() {
    let unixNow = new Date();
    unixNow = unixNow.getTime();

    if (
      this.onGoingRequest === false &&
      this.fetchDataBlocked === false &&
      this.state.logged === true &&
      this.state.alertText === ""
      //&& unixNow - this.state.lastRefreshUnix >= this.fetchDataPeriod
    ) {
      let obj = {};
      obj["reqType"] = "refresh";
      this.makeRequest(obj);
    }
  }

  setFetchDataBlocked(blocked) {
    this.fetchDataBlocked = blocked;
    if (blocked === false) {
      this.fetchDataFromServer();
    }
  }
  
  toggleUsingAdmin() {
    let args = {};
    args["reqType"] = "toggleUsingAdmin";
    this.makeRequest(args);
  }

  closeSession() {
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.setState({
      logged: false,
      error: "none",
      waitingResponse: false,
      userId: 0,
      data: {},
      isAdmin: false,
      usingAdmin: false,
      lastRefreshUnix: 0,
      alertText: "",    
    });
  }

  makeRequest(obj) {
    if (this.offline === false) {
      var componentReference = this;

      var http = new XMLHttpRequest();
      var url = this.apiURL;

      let payload = obj;

      //add JWT
      let jwt = this.getCookie("jwt");
      if (jwt != "") {
        payload["jwt"] = jwt;
      }      
      payload = JSON.stringify(payload);
      //debugger;
      http.open("POST", url, true);
      //http.setRequestHeader("Content-type","application/json");
      //http.setRequestHeader('Accept-Encoding', 'gzip'); //El browser no permite añadirlo: Refused to set unsafe header "Accept-Encoding"
      http.onreadystatechange = function() {
        //Call a function when the state changes.
        if (http.readyState === 4 && http.status === 200) {
          var resp = JSON.parse(http.responseText);
          componentReference.receiveRequest(resp);
        }
      };
      http.send(payload);
      if (obj.reqType !== "refresh" && obj.reqType) {
        this.setState({ waitingResponse: true });
      }
    } /*else if (this.offline===true && this.state.userId !== 1000){

      if (this.offlineButLoggedOff===false){
        if (obj.reqType !== "refresh"){
          this.setState({waitingResponse: true});
        }
        this.offlineServer = require('../../utils/offlineServer');
        const fakeResp = this.offlineServer.makeRequest(obj,this.offlineUser);
        setTimeout(() => this.receiveRequest(fakeResp),1000);
      }else{
        this.setState({waitingResponse: false});
      }

    }*/

    this.onGoingRequest = true;
  }

  receiveRequest(resp) {
    //debugger;
    if (resp.user.logged != null) {
      this.setState({
        logged: resp.user.logged
      });

      if (resp.user.jwt != null) {
        this.setCookie("jwt", resp.user.jwt, 24);
      }

      if (resp.user.logged === true) {        
        this.setState({
          userId: resp.user.userId,
          username: resp.user.username,
          isAdmin: resp.user.isAdmin,
          usingAdmin: resp.data.users[resp.user.userId].usingAdmin,
          data: resp.data,
          lastRefreshUnix: resp.status.time
        });
        if (resp.status.alertText != undefined) {
          this.setState({
            alertText: resp.status.alertText
          });
        }
      } else {
        this.setState({
          userId: -1,
          username: "",
          isAdmin: false,
          usingAdmin: false
        });
      }

      this.setState({ waitingResponse: false });
    } else {
      this.setState({ logged: false, waitingResponse: false });
    }

    this.onGoingRequest = false;
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exhours) {
    var d = new Date();
    d.setTime(d.getTime() + exhours * 3600 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"; //sets expiration time
    //document.cookie = cname + "=" + cvalue + ";" + ";path=/";
  }

  closeAlertText() {
    this.setState({ alertText: "" });
  }

  toggleSelectGameModal(value){    
    if (value == null){
      this.setState({showSelectGameModal: !this.state.showSelectGameModal});
    }else if (value == true){
      this.setState({showSelectGameModal: value});
    }else if (value == false){
      this.setState({showSelectGameModal: value});
    }
  }

  render() {
    if (this.state.waitingResponse === true) {
      return (
        <div className="loader">
          <Dimmer active inverted>
            <Loader size="big">Cargando...</Loader>
          </Dimmer>
        </div>
      );
    }

    if (this.state.logged === false) {
      return (
        <div className="App"  className='catanBackground2' style={{height:'100%'}}>
          <Grid padded style={{ height: "100%" }}>
            <Grid.Row style={{ height: "10%" }}>
              <Grid.Column width={16}>
                <h2 style={{ textAlign: "center", color: "yellow" }}>
                  {" "}
                  Bienvenidos a JUATAN{" "}
                </h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ height: "90%" }}>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={8}>
                <Login
                  makeRequest={this.makeRequest}
                  wrongLogin={this.state.error === "wrongLogin"}
                />
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    return (
      <div className="App catanBackground1">
        <div className="header">
          <Header
            closeSession={this.closeSession}
            toggleUsingAdmin={this.toggleUsingAdmin}
            isAdmin={this.state.isAdmin}
            usingAdmin={this.state.usingAdmin}
            logged={this.state.logged}
            lastRefreshUnix={this.state.lastRefreshUnix}
            username={this.state.username}
            gameId={this.state.data.game.gameId}
            toggleSelectGameModal={this.toggleSelectGameModal}
          />
        </div>

        <div className="notHeader catanBackground">
          <ViewGame
            data={this.state.data}
            makeRequest={this.makeRequest}
            isAdmin={this.state.isAdmin}
            usingAdmin={this.state.usingAdmin}
            userId={this.state.userId}
            username={this.state.username}             
            showSelectGameModal={this.state.showSelectGameModal}
            toggleSelectGameModal={this.toggleSelectGameModal}            
          />
        </div>
        <AlertText
          alertText={this.state.alertText}
          closeAlertText={this.closeAlertText}
        />
      </div>
    );
  }
}
