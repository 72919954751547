import React, { Component } from "react";
import { Container, Icon } from "semantic-ui-react";

export default class LogPanel extends Component {
  constructor(props) {
    super(props);
    this.matrices = require("../../tools/tools");
    /*this.state = {
      data: {}
    };*/
    this.reloadState = this.reloadState.bind(this);
  }

  componentDidMount() {
    //this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    /*if (nextProps !== this.props) {
      this.reloadState(nextProps);
    }*/
  }

  reloadState(receivedProps) {
    /*let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    newState.data = propsCopy.data;
    this.setState(newState);*/
  }

  handleChange(e, data) {
    //this.setState({ checkedPlayers: newCheckedPlayers });
  }

  render() {
    let items = [];
    for (let key in this.props.data.log) {      
      let actorItem = "";
      if (this.props.data.log[key].actorId > 0){
        const style = {
          color: this.props.data.cardSummary[this.props.data.log[key].actorId].color,
        };
        actorItem = (
        <span style={style}>{this.props.data.users[this.props.data.log[key].actorId].username + ' '}</span>            
        );
      }
      let divStyle = {};
      if (this.props.data.log[key].text == 'inicia su turno'){
        divStyle = {marginTop: '10px'};
      }
      items[Number(key)] = (
        <div style={divStyle}>
          <b>{"> "}</b>
          {actorItem}
          {this.props.data.log[key].text}
        </div>
      );      
    }

    return (
      <div>
        <p>
          <b>Registro</b>          
        </p>
        {items}
      </div>
    );
  }
}
