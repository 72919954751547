import React, { Component } from "react";
import { Container, Icon, Button } from "semantic-ui-react";

export default class NewResourcesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
    this.reloadState = this.reloadState.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    newState.data = propsCopy.data;
    this.setState(newState);
  }

  handleChange(e, data) {
    //this.setState({ checkedPlayers: newCheckedPlayers });
  }

  render() {
    return (      
        <div style={{margin: '10px'}}>
          <div>
            <Icon name='user' color={this.props.color}/>
            <b>{this.props.username}</b>
          </div>
          <div>
            <div style={{ display: "inline-block", width: "60px" }}>Trigo</div>
            <div style={{ display: "inline-block", width: "25px" }}>
              {this.props.resourcesGiven.gr}
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="minus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "gr", -1)
                }
              />
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="plus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "gr", 1)
                }
              />
            </div>
          </div>

          <div>
            <div style={{ display: "inline-block", width: "60px" }}>Lana</div>
            <div style={{ display: "inline-block", width: "25px" }}>
              {this.props.resourcesGiven.wo}
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="minus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "wo", -1)
                }
              />
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="plus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "wo", 1)
                }
              />
            </div>
          </div>
          
          <div>
            <div style={{ display: "inline-block", width: "60px" }}>Madera</div>
            <div style={{ display: "inline-block", width: "25px" }}>
              {this.props.resourcesGiven.ti}
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="minus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "ti", -1)
                }
              />
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="plus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "ti", 1)
                }
              />
            </div>
          </div>
          
          <div>
            <div style={{ display: "inline-block", width: "60px" }}>Arcilla</div>
            <div style={{ display: "inline-block", width: "25px" }}>
              {this.props.resourcesGiven.cl}
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="minus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "cl", -1)
                }
              />
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="plus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "cl", 1)
                }
              />
            </div>
          </div>

          <div>
            <div style={{ display: "inline-block", width: "60px" }}>Piedra</div>
            <div style={{ display: "inline-block", width: "25px" }}>
              {this.props.resourcesGiven.st}
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="minus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "st", -1)
                }
              />
            </div>
            <div style={{ display: "inline-block", width: "30px" }}>
              <Button
                circular
                icon="plus"
                size="mini"
                onClick={() =>
                  this.props.handleChange(this.props.userId, "st", 1)
                }
              />
            </div>
          </div>
          
          
          
          
        </div>

        
    );
  }
}
