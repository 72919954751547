import React, { Component } from "react";
import { Container, Button, Input, Modal, Checkbox } from "semantic-ui-react";

export default class MoveThiefPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,      
    };

    this.reloadState = this.reloadState.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {    
    this.setState({
      validData: false,      
    });
  }

  validate(terrainValue) {
    let result = true;
    if (!Number.isInteger(terrainValue)){
      result = false;
    }else if (terrainValue < 1){
      result = false;
    }else if (terrainValue > 30){
      result = false;
    }

    return result;
  }

  handleSave() {
    //make request
    if (this.validate(this.props.thiefTerrain)) {
      let args = {};
      args["reqType"] = "moveThief";
      args["data"] = this.props.thiefTerrain;
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.updateThiefTerrain(0);
    this.props.hideModal();    
  }

  handleChange(e, data) {
    if (Number.isInteger(Number(data.value))) {
      this.props.updateThiefTerrain(data.value);
      this.setState({validData: true});
    }
  }


  render() {
    return (
      <div>        
        <div>
          <h4 style={{display: 'inline-block', width: '60%'}}>Mover el ladrón</h4>
          <Button
            size="tiny"
            color={this.props.showNodeIds ? "green" : "grey"}
            icon="hashtag"
            content="IDs"
            onClick={() => {
              this.props.toggleNodeIds();
            }}
          />
        </div>
        <div>
          <div>Haz click en el terreno donde deseas que vaya el ladrón</div>
          <div>
            {this.props.showNodeIds && (
              <Input
                style={{ marginTop: "20px" }}
                fluid
                placeholder="ID del terreno"
                name="terrain"
                value={this.props.thiefTerrain}
                onChange={this.handleChange}
              />
            )}
          </div>
        </div>
        <div style={{marginTop: '25px'}}>
          <Button
            negative
            labelPosition="right"
            icon="close"
            content="Cancelar"
            onClick={() => this.handleCancel()}
          />
          <Button
            positive
            labelPosition="right"
            icon="checkmark"
            content="Mover"
            onClick={() => this.handleSave()}
            disabled={!Number.isInteger(this.props.thiefTerrain) || this.props.thiefTerrain < 1 || this.props.thiefTerrain > 30}
          />
        </div>
      </div>
    );
  }
}
