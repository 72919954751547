import React, { Component } from "react";
import { Container, Button, Checkbox, Modal, Input } from "semantic-ui-react";

export default class CreateGameModal extends Component {
  constructor(props) {
    super(props);
    this.matrices = require("../../tools/tools");
    this.state = {
      validData: false,
      description: "",
      checkedPlayers: []
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);    
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      // No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let checkedPlayersAux = [];
    Object.keys(propsCopy.users).forEach(
      key => (checkedPlayersAux[key] = false)
    );    
    
    this.setState({
      checkedPlayers: checkedPlayersAux,
      description: "",
    });
  }

  handleChange(e, data) {
    let newCheckedPlayers = this.state.checkedPlayers;
    newCheckedPlayers[data.id] = !newCheckedPlayers[data.id];
    this.setState({ checkedPlayers: newCheckedPlayers });
  }  

  handleChangeDescription(e, data) {        
    this.setState({ description: data.value });
  }

  validate() {
    let checkedSum = 0;
    this.state.checkedPlayers.forEach(function(item, index) {
      if (item) checkedSum++;
    });
    if (checkedSum >= 3 && checkedSum <=6) {
      return true;
    }
    return false;
  }

  handleSave() {
    //make request
    if (this.validate()) {
      let confirmAction = window.confirm(
        "¿Desea continuar?"
      );
      if (confirmAction === true) {
        let args = {};
        args["reqType"] = "createGame";
        args["data"] = {};  
        args['data']['players'] = [];

        let array = Object.keys(this.state.checkedPlayers);
        let pos = 0;
        for (let i = 0; i < array.length; i++) {          
          if (this.state.checkedPlayers[array[i]]){              
              args['data']['players'][pos] = Number(array[i]);
              pos++;
          }
        }                
        args['data']['description'] = this.state.description;                
        this.props.makeRequest(args);
      }
    } else {
      alert("Número de jugadores debe ser entre 3 y 6");
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  render() {
    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Crear nueva partida"}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Descripción:</p>
              <Input
                style={{ marginTop: "10px", marginBottom: "20px" }}
                fluid
                placeholder="Introduce una descripción si deseas"
                name="description"
                value={this.state.description}
                onChange={this.handleChangeDescription}
              />

              <p>Jugadores:</p>
              {Object.values(this.props.users).map(user => (
                <div>
                  <Checkbox
                    checked={this.state.checkedPlayers[user["userId"]]}                    
                    id={user["userId"]}
                    label={user["username"]}
                    onChange={this.handleChange}
                  />
                  <br></br>
                </div>
              ))}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Cancelar"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="checkmark"
              content="Guardar cambios"
              onClick={() => this.handleSave()}
              disabled={this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
