import React, { Component } from "react";
import { Container, Button, Checkbox, Modal, Image } from "semantic-ui-react";

export default class BuyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validData: false,
      purchases: [0, 0, 0, 0],
      resourcesLeft: [0, 0, 0, 0, 0]
    };
    this.reloadState = this.reloadState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.validate = this.validate.bind(this);

    this.resourceName = ["gr", "wo", "ti", "cl", "st"];
    this.cost = [];
    this.cost[0] = [0, 0, 1, 1, 0];
    this.cost[1] = [1, 1, 1, 1, 0];
    this.cost[2] = [2, 0, 0, 0, 3];
    this.cost[3] = [1, 1, 0, 0, 1];
  }

  componentDidMount() {
    this.reloadState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //No update as every refresh from server would reset data
      //this.reloadState(nextProps);
    }
  }

  reloadState(receivedProps) {
    let propsCopy = JSON.parse(JSON.stringify(receivedProps));
    let newState = this.state;
    newState.purchases = [0, 0, 0, 0];
    newState.resourcesLeft = [
      this.props.data.cards[this.props.userId].gr,
      this.props.data.cards[this.props.userId].wo,
      this.props.data.cards[this.props.userId].ti,
      this.props.data.cards[this.props.userId].cl,
      this.props.data.cards[this.props.userId].st
    ];
    this.setState(newState);
  }

  handleChange(item, delta) {
    let newState = JSON.parse(JSON.stringify(this.state));

    let validAux = true;
    for (let i = 0; i < 5; i++) {
      newState.resourcesLeft[i] -= delta * this.cost[item][i];
      if (newState.resourcesLeft[i] < 0) {
        validAux = false;
      }
    }

    //check enough towns for a city
    if (item == 2 && delta > 0) {
      //debugger;
      if (
        this.props.data.cards[this.props.userId].tow <
        newState.purchases[2] + delta
      ) {
        validAux = false;
      }
    }

    //Check not too many roads or towns or cities
    if (delta > 0) {
      if (item == 0 && this.props.data.cards[this.props.userId].ro + newState.purchases[0] + delta > 15){
        validAux = false;
      }
      if (item == 1 && this.props.data.cards[this.props.userId].tow + newState.purchases[1] + delta > 5){
        validAux = false;
      }
      if (item == 2 && this.props.data.cards[this.props.userId].ci + newState.purchases[2] + delta > 5){
        validAux = false;
      }
    }

    //Check enough development cards available
    if (item == 3 && delta > 0) {
      if (this.props.data.game.nextCard + newState.purchases[3] + delta > 25) {
        validAux = false;
      }
    }

    if (validAux) {
      newState.purchases[item] += delta;
      if (newState.purchases[item] >= 0) {
        newState.validData = this.validate(newState);
        this.setState(newState);
      }
    }
  }

  validate(stateChecked) {
    for (let i = 0; i < 5; i++) {
      if (stateChecked.resourcesLeft[i] < 0) {
        return false;
      }
    }
    let onePurchase = false;
    for (let i = 0; i < 4; i++) {
      if (stateChecked.purchases[i] > 0) {
        onePurchase = true;
      }
    }
    return onePurchase;
  }

  handleSave() {
    //make request
    if (this.validate(this.state)) {
      let args = {};
      args["reqType"] = "makePurchase";
      args["data"] = this.state.purchases;
      this.props.makeRequest(args);
    }
  }

  handleCancel() {
    this.props.hideModal();
  }

  render() {    
    return (
      <Container>
        <Modal
          open
          centered={false}
          dimmer={"inverted"}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => this.handleCancel()}
        >
          <Modal.Header>{"Comprar"}</Modal.Header>
          <Modal.Content>
            <div className="resource-grid">
              <div className="resource-column-1">
                <div>
                  <b>Recursos disponibles</b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Trigo
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesLeft[0]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Lana
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesLeft[1]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Madera
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesLeft[2]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Arcilla
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesLeft[3]}
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Piedra
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.resourcesLeft[4]}
                  </div>
                </div>
              </div>

              <div className="resource-column-2">
                <div>
                  <b>Compras</b>
                </div>
                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Carretera
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.purchases[0]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={this.state.purchases[0] <= 0}
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange(0, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled = {this.props.data.cards[this.props.userId].ro + this.state.purchases[0] >= 15}
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange(0, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Poblado
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.purchases[1]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={this.state.purchases[1] <= 0}
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange(1, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled = {this.props.data.cards[this.props.userId].tow + this.state.purchases[1] >= 5 || this.state.purchases[1] > 0 || this.state.purchases[2] > 0}
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange(1, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Ciudad
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.purchases[2]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={this.state.purchases[2] <= 0}
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange(2, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={
                        this.props.data.cards[this.props.userId].tow <= this.state.purchases[2]
                        || this.props.data.cards[this.props.userId].ci + this.state.purchases[2] >= 5  || this.state.purchases[1] > 0
                      }
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange(2, 1)}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ display: "inline-block", width: "120px" }}>
                    Carta desarrollo
                  </div>
                  <div style={{ display: "inline-block", width: "50px" }}>
                    {this.state.purchases[3]}
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={this.state.purchases[3] <= 0}
                      circular
                      icon="minus"
                      size="mini"
                      onClick={() => this.handleChange(3, -1)}
                    />
                  </div>
                  <div style={{ display: "inline-block", width: "30px" }}>
                    <Button
                      disabled={
                        this.props.data.game.nextCard +
                          this.state.purchases[3] >=
                        25
                      }
                      circular
                      icon="plus"
                      size="mini"
                      onClick={() => this.handleChange(3, 1)}
                    />
                  </div>
                </div>
              </div>

              <div className="resource-column-3">
                <Image src="/images/costs.jpg" size="medium" rounded />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              labelPosition="right"
              icon="close"
              content="Cancelar"
              onClick={() => this.handleCancel()}
            />
            <Button
              positive
              labelPosition="right"
              icon="shopping bag"
              content="Realizar compra"
              onClick={() => this.handleSave()}
              disabled={!this.state.validData}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
