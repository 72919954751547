/* @flow */
import React, { Component } from "react";
import { Icon, Popup, Grid } from "semantic-ui-react";

export default class TurnPanelCard extends Component {
  constructor(props) {
    super(props);
    this.tools = require("../../../tools/tools.js");
  }

  render() {
    const ColoredLine = () => (
      <hr
        style={{
          color: "black",
          backgroundColor: "black",
          height: 1
        }}
      />
    );

    let totalDices = 0;
    for (let i=2;i<=12;i++){
      totalDices += this.props.dices[i].amount;      
    }
    if (totalDices == 0){
      totalDices=1;
    }

    let diceProb = [];
    diceProb[2] = 2.8;
    diceProb[3] = 5.6;
    diceProb[4] = 8.3;
    diceProb[5] = 11.1;
    diceProb[6] = 13.9;
    diceProb[7] = 16.7;
    diceProb[8] = 13.9;
    diceProb[9] = 11.1;
    diceProb[10] = 8.3;
    diceProb[11] = 5.6;
    diceProb[12] = 2.8;
    
    let dicePopup = [];
    dicePopup[1]=(<div style={{width: '300px'}}>
        <div style={{display:'inline-block', width: '30px', textAlign: 'right'}}>x</div>
        <div style={{display:'inline-block', width: '30px', textAlign: 'right'}}>n</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>%</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>% teo</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>desv</div>
      </div>);
    for (let i=2;i<=12;i++){
      dicePopup[i]=(<div style={{width: '300px'}}>
        <div style={{display:'inline-block', width: '30px', textAlign: 'right'}}>{this.props.dices[i].number + ':'}</div>
        <div style={{display:'inline-block', width: '30px', textAlign: 'right'}}>{this.props.dices[i].amount}</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>{Math.round(1000*this.props.dices[i].amount/totalDices)/10 + ' %'}</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>{diceProb[i] + ' %'}</div>
        <div style={{display:'inline-block', width: '60px', textAlign: 'right'}}>{Math.round(100*((100*this.props.dices[i].amount/totalDices)-diceProb[i])/diceProb[i]) + ' %'}</div>
      </div>);
    }
    

    return (
      <div>
        <div>
          <div>
            <div style={{ display: "inline-block", width: "80%" }}>
              <Icon name="user" color={this.props.color} />
              <b>{this.props.user.username}</b>
            </div>
            <div style={{ display: "inline-block", width: "20%" }}>
              {this.props.showDice == true && (
                <Popup
                  content={dicePopup}
                  trigger={
                    <Icon name="cube" style={{ display: "inline-block" }} />
                  }
                />
              )}
              {this.props.showDice == true && this.props.game.dice != 0 && (
                <p style={{ fontSize: "16px", display: "inline-block" }}>
                  {this.props.game.dice}
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="resource-grid">
              <div className="resource-column-1">
                <Popup
                  content="Cartas de materia prima"
                  trigger={
                    <div>
                      <Icon name="clone" />
                      {this.props.cardSummary.totalResources}
                    </div>
                  }
                />

                <Popup
                  content="Cartas de desarrollo"
                  trigger={
                    <div>
                      <Icon name="window restore" />
                      {this.props.cardSummary.totalCovered}
                    </div>
                  }
                />

                <Popup
                  content="Caballeros usados"
                  trigger={
                    <div>
                      <Icon name="id badge outline" />
                      {this.props.cardSummary.totalUncovered}
                    </div>
                  }
                />
              </div>
              <div className="resource-column-2">
                <Popup
                  content="Carreteras"
                  trigger={
                    <div>
                      <Icon name="road" />
                      {this.props.cardSummary.ro}
                    </div>
                  }
                />
                <Popup
                  content="Poblados"
                  trigger={
                    <div>
                      <Icon name="home" />
                      {this.props.cardSummary.tow}
                    </div>
                  }
                />
                <Popup
                  content="Ciudades"
                  trigger={
                    <div>
                      <Icon name="building" />
                      {this.props.cardSummary.ci}
                    </div>
                  }
                />
              </div>
              <div className="resource-column-3">
                <Popup
                  content="Tiempo acumulado"
                  trigger={
                    <div>
                      <Icon name="clock" />
                      {this.tools.getHHMMSS(this.props.cardSummary.totalTime)}
                    </div>
                  }
                />
                <Popup
                  content="Puntos de victoria a la vista"
                  trigger={
                    <div>
                      <Icon name="star" />
                      {this.props.cardSummary.points}
                    </div>
                  }
                />
                <div>
                  {this.props.army && (
                    <Popup
                      content="Gran armada"
                      trigger={
                        <div style={{ margin: "10px", display: "inline" }}>
                          <Icon
                            color="yellow"
                            name="pied piper alternate"
                            size="large"
                          />
                        </div>
                      }
                    />
                  )}
                  {this.props.route && (
                    <Popup
                      content="Gran ruta comercial"
                      trigger={
                        <div style={{ margin: "10px", display: "inline" }}>
                          <Icon color="yellow" name="renren" size="large" />
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {true && <ColoredLine />}
      </div>
    );
  }
}
