//Aux
let roadId;
let start; //Start node of road
let end; //End node of road
let step; //Vertical road node difference
let removeTips; //Remove vertical roads of start and end nodes
let firstX;
let firstY;
let firstZ;
let secondY;
let secondZ;
let offset;

//node coordinates
export let nodeCoord = [];
nodeCoord[1] = [];
nodeCoord[2] = [];

for (let i = 1; i < 55; i++) {
  nodeCoord[1][i] = [];
}

//Coord x
nodeCoord[1][1]["x"] = 2;
nodeCoord[1][2]["x"] = 3;
nodeCoord[1][3]["x"] = 4;
nodeCoord[1][4]["x"] = 5;
nodeCoord[1][5]["x"] = 6;
nodeCoord[1][6]["x"] = 7;
nodeCoord[1][7]["x"] = 8;
nodeCoord[1][8]["x"] = 1;
nodeCoord[1][9]["x"] = 2;
nodeCoord[1][10]["x"] = 3;
nodeCoord[1][11]["x"] = 4;
nodeCoord[1][12]["x"] = 5;
nodeCoord[1][13]["x"] = 6;
nodeCoord[1][14]["x"] = 7;
nodeCoord[1][15]["x"] = 8;
nodeCoord[1][16]["x"] = 9;
nodeCoord[1][17]["x"] = 0;
nodeCoord[1][18]["x"] = 1;
nodeCoord[1][19]["x"] = 2;
nodeCoord[1][20]["x"] = 3;
nodeCoord[1][21]["x"] = 4;
nodeCoord[1][22]["x"] = 5;
nodeCoord[1][23]["x"] = 6;
nodeCoord[1][24]["x"] = 7;
nodeCoord[1][25]["x"] = 8;
nodeCoord[1][26]["x"] = 9;
nodeCoord[1][27]["x"] = 10;
nodeCoord[1][28]["x"] = 0;
nodeCoord[1][29]["x"] = 1;
nodeCoord[1][30]["x"] = 2;
nodeCoord[1][31]["x"] = 3;
nodeCoord[1][32]["x"] = 4;
nodeCoord[1][33]["x"] = 5;
nodeCoord[1][34]["x"] = 6;
nodeCoord[1][35]["x"] = 7;
nodeCoord[1][36]["x"] = 8;
nodeCoord[1][37]["x"] = 9;
nodeCoord[1][38]["x"] = 10;
nodeCoord[1][39]["x"] = 1;
nodeCoord[1][40]["x"] = 2;
nodeCoord[1][41]["x"] = 3;
nodeCoord[1][42]["x"] = 4;
nodeCoord[1][43]["x"] = 5;
nodeCoord[1][44]["x"] = 6;
nodeCoord[1][45]["x"] = 7;
nodeCoord[1][46]["x"] = 8;
nodeCoord[1][47]["x"] = 9;
nodeCoord[1][48]["x"] = 2;
nodeCoord[1][49]["x"] = 3;
nodeCoord[1][50]["x"] = 4;
nodeCoord[1][51]["x"] = 5;
nodeCoord[1][52]["x"] = 6;
nodeCoord[1][53]["x"] = 7;
nodeCoord[1][54]["x"] = 8;

//Coord y
nodeCoord[1][1]["y"] = 1;
nodeCoord[1][2]["y"] = 0;
nodeCoord[1][3]["y"] = 1;
nodeCoord[1][4]["y"] = 0;
nodeCoord[1][5]["y"] = 1;
nodeCoord[1][6]["y"] = 0;
nodeCoord[1][7]["y"] = 1;
nodeCoord[1][8]["y"] = 2;
nodeCoord[1][9]["y"] = 1;
nodeCoord[1][10]["y"] = 2;
nodeCoord[1][11]["y"] = 1;
nodeCoord[1][12]["y"] = 2;
nodeCoord[1][13]["y"] = 1;
nodeCoord[1][14]["y"] = 2;
nodeCoord[1][15]["y"] = 1;
nodeCoord[1][16]["y"] = 2;
nodeCoord[1][17]["y"] = 3;
nodeCoord[1][18]["y"] = 2;
nodeCoord[1][19]["y"] = 3;
nodeCoord[1][20]["y"] = 2;
nodeCoord[1][21]["y"] = 3;
nodeCoord[1][22]["y"] = 2;
nodeCoord[1][23]["y"] = 3;
nodeCoord[1][24]["y"] = 2;
nodeCoord[1][25]["y"] = 3;
nodeCoord[1][26]["y"] = 2;
nodeCoord[1][27]["y"] = 3;
nodeCoord[1][28]["y"] = 3;
nodeCoord[1][29]["y"] = 4;
nodeCoord[1][30]["y"] = 3;
nodeCoord[1][31]["y"] = 4;
nodeCoord[1][32]["y"] = 3;
nodeCoord[1][33]["y"] = 4;
nodeCoord[1][34]["y"] = 3;
nodeCoord[1][35]["y"] = 4;
nodeCoord[1][36]["y"] = 3;
nodeCoord[1][37]["y"] = 4;
nodeCoord[1][38]["y"] = 3;
nodeCoord[1][39]["y"] = 4;
nodeCoord[1][40]["y"] = 5;
nodeCoord[1][41]["y"] = 4;
nodeCoord[1][42]["y"] = 5;
nodeCoord[1][43]["y"] = 4;
nodeCoord[1][44]["y"] = 5;
nodeCoord[1][45]["y"] = 4;
nodeCoord[1][46]["y"] = 5;
nodeCoord[1][47]["y"] = 4;
nodeCoord[1][48]["y"] = 5;
nodeCoord[1][49]["y"] = 6;
nodeCoord[1][50]["y"] = 5;
nodeCoord[1][51]["y"] = 6;
nodeCoord[1][52]["y"] = 5;
nodeCoord[1][53]["y"] = 6;
nodeCoord[1][54]["y"] = 5;

//Coord z
nodeCoord[1][1]["z"] = 0;
nodeCoord[1][2]["z"] = 1;
nodeCoord[1][3]["z"] = 0;
nodeCoord[1][4]["z"] = 1;
nodeCoord[1][5]["z"] = 0;
nodeCoord[1][6]["z"] = 1;
nodeCoord[1][7]["z"] = 0;
nodeCoord[1][8]["z"] = 1;
nodeCoord[1][9]["z"] = 2;
nodeCoord[1][10]["z"] = 1;
nodeCoord[1][11]["z"] = 2;
nodeCoord[1][12]["z"] = 1;
nodeCoord[1][13]["z"] = 2;
nodeCoord[1][14]["z"] = 1;
nodeCoord[1][15]["z"] = 2;
nodeCoord[1][16]["z"] = 1;
nodeCoord[1][17]["z"] = 2;
nodeCoord[1][18]["z"] = 3;
nodeCoord[1][19]["z"] = 2;
nodeCoord[1][20]["z"] = 3;
nodeCoord[1][21]["z"] = 2;
nodeCoord[1][22]["z"] = 3;
nodeCoord[1][23]["z"] = 2;
nodeCoord[1][24]["z"] = 3;
nodeCoord[1][25]["z"] = 2;
nodeCoord[1][26]["z"] = 3;
nodeCoord[1][27]["z"] = 2;
nodeCoord[1][28]["z"] = 4;
nodeCoord[1][29]["z"] = 3;
nodeCoord[1][30]["z"] = 4;
nodeCoord[1][31]["z"] = 3;
nodeCoord[1][32]["z"] = 4;
nodeCoord[1][33]["z"] = 3;
nodeCoord[1][34]["z"] = 4;
nodeCoord[1][35]["z"] = 3;
nodeCoord[1][36]["z"] = 4;
nodeCoord[1][37]["z"] = 3;
nodeCoord[1][38]["z"] = 4;
nodeCoord[1][39]["z"] = 5;
nodeCoord[1][40]["z"] = 4;
nodeCoord[1][41]["z"] = 5;
nodeCoord[1][42]["z"] = 4;
nodeCoord[1][43]["z"] = 5;
nodeCoord[1][44]["z"] = 4;
nodeCoord[1][45]["z"] = 5;
nodeCoord[1][46]["z"] = 4;
nodeCoord[1][47]["z"] = 5;
nodeCoord[1][48]["z"] = 6;
nodeCoord[1][49]["z"] = 5;
nodeCoord[1][50]["z"] = 6;
nodeCoord[1][51]["z"] = 5;
nodeCoord[1][52]["z"] = 6;
nodeCoord[1][53]["z"] = 5;
nodeCoord[1][54]["z"] = 6;

//nodeCoord[2]

for (let i = 1; i <= 80; i++) {
  nodeCoord[2][i] = [];
}

//Fila 1
start = 1;
end = 9;
firstX = 2;
firstY = 1;
secondY = 0;
firstZ = 0;
secondZ = 1;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 2
start = 10;
end = 20;
firstX = 1;
firstY = 2;
secondY = 1;
firstZ = 1;
secondZ = 2;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 3
start = 21;
end = 33;
firstX = 0;
firstY = 3;
secondY = 2;
firstZ = 2;
secondZ = 3;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 4
start = 34;
end = 47;
firstX = 0;
firstY = 3;
secondY = 4;
firstZ = 4;
secondZ = 3;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 5
start = 48;
end = 60;
firstX = 1;
firstY = 4;
secondY = 5;
firstZ = 5;
secondZ = 4;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 6
start = 61;
end = 71;
firstX = 2;
firstY = 5;
secondY = 6;
firstZ = 6;
secondZ = 5;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}
//Fila 7
start = 72;
end = 80;
firstX = 3;
firstY = 6;
secondY = 7;
firstZ = 7;
secondZ = 6;
for (let i=start;i<=end;i++){
  nodeCoord[2][i]['x'] = firstX + (i-start);
  if ((i - start) % 2 == 0){
    nodeCoord[2][i]['y'] = firstY;
    nodeCoord[2][i]['z'] = firstZ;
  }else{
    nodeCoord[2][i]['y'] = secondY;
    nodeCoord[2][i]['z'] = secondZ;
  }
}

//roadNodes ARE NOT NEEDED. NOT USED
export let roadNodes = [];
roadNodes[1] = [];
roadNodes[2] = [];


roadId = 0
//Line 1
start = 1;
end = 7;
step = 8;
removeTips = false;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}

//Line 2
start = 8;
end = 16;
step = 10;
removeTips = false;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}

//Line 3
start = 17;
end = 27;
step = 11;
removeTips = false;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}

//Line 4
start = 28;
end = 38;
step = 10;
removeTips = true;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}

//Line 5
start = 39;
end = 47;
step = 8;
removeTips = true;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}

//Line 6
start = 48;
end = 54;
step = 0;
removeTips = true;
for (let i = start; i < end; i++) {
  roadId++;
  roadNodes[1][roadId] = [];
  roadNodes[1][roadId][0] = i;
  roadNodes[1][roadId][1] = i + 1;
}
if (step != 0) {
  for (let i = start; i <= end; i++) {
    if (!removeTips || (i != start && i != end)) {
      let offset = removeTips ? 1 : 0;
      if ((i - start+ offset) % 2 == 0) {
        roadId++;
        roadNodes[1][roadId] = [];
        roadNodes[1][roadId][0] = i;
        roadNodes[1][roadId][1] = i + step;
      }
    }
  }
}


//roadNodes[2] NOT NEEDED

//terrain nodes
export let terrainNodes = [];
terrainNodes[1] = [];
terrainNodes[2] = [];



//terrainNodes[1]
//line 1
start = 1;
end = 3;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[1][i] = [];
  terrainNodes[1][i]["x"] = 3 + offset * 2;
  terrainNodes[1][i]["y"] = 1;
  terrainNodes[1][i]["z"] = 1;  
  terrainNodes[1][i][1] = 1 + offset * 2;
  terrainNodes[1][i][2] = 2 + offset * 2;
  terrainNodes[1][i][3] = 3 + offset * 2;
  terrainNodes[1][i][4] = 11 + offset * 2;
  terrainNodes[1][i][5] = 10 + offset * 2;
  terrainNodes[1][i][6] = 9 + offset * 2;
  offset++;
}

//line 2
start = 4;
end = 7;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[1][i] = [];
  terrainNodes[1][i]["x"] = 2 + offset * 2;
  terrainNodes[1][i]["y"] = 2;
  terrainNodes[1][i]["z"] = 2;  
  terrainNodes[1][i][1] = 9 + offset * 2;
  terrainNodes[1][i][2] = 10 + offset * 2;
  terrainNodes[1][i][3] = 11 + offset * 2;
  terrainNodes[1][i][4] = 20 + offset * 2;
  terrainNodes[1][i][5] = 19 + offset * 2;
  terrainNodes[1][i][6] = 18 + offset * 2;
  offset++;
}

//line 3
start = 8;
end = 12;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[1][i] = [];
  terrainNodes[1][i]["x"] = 1 + offset * 2;
  terrainNodes[1][i]["y"] = 3;
  terrainNodes[1][i]["z"] = 3;  
  terrainNodes[1][i][1] = 17 + offset * 2;
  terrainNodes[1][i][2] = 18 + offset * 2;
  terrainNodes[1][i][3] = 19 + offset * 2;
  terrainNodes[1][i][4] = 30 + offset * 2;
  terrainNodes[1][i][5] = 29 + offset * 2;
  terrainNodes[1][i][6] = 28 + offset * 2;
  offset++;
}

//line 4
start = 13;
end = 16;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[1][i] = [];
  terrainNodes[1][i]["x"] = 2 + offset * 2;
  terrainNodes[1][i]["y"] = 4;
  terrainNodes[1][i]["z"] = 4;  
  terrainNodes[1][i][1] = 29 + offset * 2;
  terrainNodes[1][i][2] = 30 + offset * 2;
  terrainNodes[1][i][3] = 31 + offset * 2;
  terrainNodes[1][i][4] = 41 + offset * 2;
  terrainNodes[1][i][5] = 40 + offset * 2;
  terrainNodes[1][i][6] = 39 + offset * 2;
  offset++;
}

//line 5
start = 17;
end = 19;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[1][i] = [];
  terrainNodes[1][i]["x"] = 3 + offset * 2;
  terrainNodes[1][i]["y"] = 5;
  terrainNodes[1][i]["z"] = 5;  
  terrainNodes[1][i][1] = 40 + offset * 2;
  terrainNodes[1][i][2] = 41 + offset * 2;
  terrainNodes[1][i][3] = 42 + offset * 2;
  terrainNodes[1][i][4] = 50 + offset * 2;
  terrainNodes[1][i][5] = 49 + offset * 2;
  terrainNodes[1][i][6] = 48 + offset * 2;
  offset++;
}

//terrainNodes[2]
//line 1
start = 1;
end = 4;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 3 + offset * 2;
  terrainNodes[2][i]["y"] = 1;
  terrainNodes[2][i]["z"] = 1;  
  terrainNodes[2][i][1] = 1 + offset * 2;
  terrainNodes[2][i][2] = 2 + offset * 2;
  terrainNodes[2][i][3] = 3 + offset * 2;
  terrainNodes[2][i][4] = 13 + offset * 2;
  terrainNodes[2][i][5] = 12 + offset * 2;
  terrainNodes[2][i][6] = 11 + offset * 2;
  offset++;
}

//line 2
start = 5;
end = 9;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 2 + offset * 2;
  terrainNodes[2][i]["y"] = 2;
  terrainNodes[2][i]["z"] = 2;  
  terrainNodes[2][i][1] = 10 + offset * 2;
  terrainNodes[2][i][2] = 11 + offset * 2;
  terrainNodes[2][i][3] = 12 + offset * 2;
  terrainNodes[2][i][4] = 24 + offset * 2;
  terrainNodes[2][i][5] = 23 + offset * 2;
  terrainNodes[2][i][6] = 22 + offset * 2;
  offset++;
}

//line 3
start = 10;
end = 15;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 1 + offset * 2;
  terrainNodes[2][i]["y"] = 3;
  terrainNodes[2][i]["z"] = 3;  
  terrainNodes[2][i][1] = 21 + offset * 2;
  terrainNodes[2][i][2] = 22 + offset * 2;
  terrainNodes[2][i][3] = 23 + offset * 2;
  terrainNodes[2][i][4] = 36 + offset * 2;
  terrainNodes[2][i][5] = 35 + offset * 2;
  terrainNodes[2][i][6] = 34 + offset * 2;
  offset++;
}

//line 4
start = 16;
end = 21;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 2 + offset * 2;
  terrainNodes[2][i]["y"] = 4;
  terrainNodes[2][i]["z"] = 4;  
  terrainNodes[2][i][1] = 35 + offset * 2;
  terrainNodes[2][i][2] = 36 + offset * 2;
  terrainNodes[2][i][3] = 37 + offset * 2;
  terrainNodes[2][i][4] = 50 + offset * 2;
  terrainNodes[2][i][5] = 49 + offset * 2;
  terrainNodes[2][i][6] = 48 + offset * 2;
  offset++;
}

//line 5
start = 22;
end = 26;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 3 + offset * 2;
  terrainNodes[2][i]["y"] = 5;
  terrainNodes[2][i]["z"] = 5;  
  terrainNodes[2][i][1] = 49 + offset * 2;
  terrainNodes[2][i][2] = 50 + offset * 2;
  terrainNodes[2][i][3] = 51 + offset * 2;
  terrainNodes[2][i][4] = 63 + offset * 2;
  terrainNodes[2][i][5] = 62 + offset * 2;
  terrainNodes[2][i][6] = 61 + offset * 2;
  offset++;
}

//line 6
start = 27;
end = 30;
offset = 0;
for (let i = start; i <= end; i++) {
  terrainNodes[2][i] = [];
  terrainNodes[2][i]["x"] = 4 + offset * 2;
  terrainNodes[2][i]["y"] = 6;
  terrainNodes[2][i]["z"] = 6;  
  terrainNodes[2][i][1] = 62 + offset * 2;
  terrainNodes[2][i][2] = 63 + offset * 2;
  terrainNodes[2][i][3] = 64 + offset * 2;
  terrainNodes[2][i][4] = 72 + offset * 2;
  terrainNodes[2][i][5] = 73 + offset * 2;
  terrainNodes[2][i][6] = 74 + offset * 2;
  offset++;
}